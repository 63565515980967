// Customizable Area Start
import React from "react";
import ChooseDatesTimeController, { Props } from "./ChooseDatesTimeContoller.web";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
    Typography,
    Box,
    styled,
    TextField
} from "@mui/material";
import { pickdate, timer } from "../assets";
import { configJSON } from "../ProjectTemplatesController";
// Customizable Area End

// Customizable Area Start
const CalendarWrapper = styled(Box)({
    "& .react-calendar": {
        width: "600px",
        height: "auto",
        border: "1.65px solid #E2E8F0",
        borderRadius: '16px',
        boxShadow: "0px 13.19px 52.75px 0px #00000008",
        background: "#FFFFFF"
    },
    "& .react-calendar abbr": {
        textDecoration: "none",
        border: "none",
    },
    "& .react-calendar__month-view__days": {
        height: "340px"
    },
    "& .react-calendar__month-view__weekdays": {
        color: "#64748B",
        fontFamily: "Inter",
        fontSize: "20.08px",
        fontWeight: 400,
        textAlign: "center",
        textTransform: "capitalize",
        textDecoration: "white"
    },
    "& .react-calendar__tile.react-calendar__year-view__months__month": {
        fontSize: "18px", 
    },
    "& .react-calendar__tile.react-calendar__decade-view__years__year":{
        fontSize: "18px",
    },
    "& .react-calendar__navigation__label": {
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "23.08px",
        fontWeight: 700,
        lineHeight: "36.26px",
        textAlign: "center",
        textTransform: "capitalize",
        textDecoration: "none"
    },
    "& .react-calendar__tile": {
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "23.08px",
        fontWeight: 400,
        textAlign: "center",
    },
    "& .react-calendar__navigation__next2-button ": {
        display: "none"
    },
    "& .react-calendar__navigation__prev2-button": {
        display: "none"
    },
    "& .react-calendar__navigation__prev-button": {
        width: "39.56px"
    },
    "& .react-calendar__navigation__arrow": {
        fontSize: "40px" /* Adjust the width as needed */
    },
    "& .react-calendar__tile.highlight": {
        background: "#87C122"
    },
    "@media (max-width: 1176px)": {
        "& .react-calendar": {
            width: "auto"
        }
    },

});

const InputWrapper = styled(Box)({
    position: "relative",
    marginBottom: "24px",
    width: "600px",
    zIndex: 2,
    "@media (max-width: 800px)": {
        width: "90%",
    },
});


const ContactusCustomLabel = styled(Typography)({
    position: "absolute",
    top: "-30px",
    backgroundColor: "white",
    padding: "0 4px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    zIndex: 1,
    marginBottom: "8px",
    background: "transparent"
});

const Choosedatetimewraaper = styled(Typography)({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: "40px",
    "@media (max-width: 600px)": {
        width: "100%",
        paddingTop: "20px"
    },
});

const Imagewrapper = styled(Typography)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 1300px)": {
        justifyContent: "unset",
    },
});

const CalendarImagebox = styled(Typography)({
    display: "flex",
    width: "90%",
    justifyContent: "space-between",
    "@media (max-width: 1300px)": {
        justifyContent: "unset",
        gap: "20px"
    },
    "@media (max-width: 800px)": {
        flexDirection: "column"
    },
});

const Imagebox = styled(Typography)({
    width: "50%",
    "@media (max-width: 1050px)": {
        width: "100%"
    },
    "@media (max-width: 800px)": {
        display: "flex",
        justifyContent: "center"
    },
});
const Image = styled("img")({
    height: "427px",
    "@media (max-width: 1050px)": {
        width: "120%",
        height: "427px"
    },
    "@media (max-width: 800px)": {
        width: "auto",
        height: "100%"
    },
    "@media (max-width: 600px)": {
        width: "100%",
        height: "100%"
    },
});



// Customizable Area End

export default class ChooseDatesTime extends ChooseDatesTimeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Choosedatetimewraaper style={{}}>
                <CalendarImagebox>
                    <CalendarWrapper >
                        <Calendar minDate={new Date()} onClickDay={(date: any) => this.handleDateClick(date)}
                            value={this.state.selectedDate}
                            tileClassName={({ date }) => {
                                return this.state.selectedDate && date.toDateString() === this.state.selectedDate.toDateString()
                                    ? 'highlight'
                                    : '';
                            }} />
                    </CalendarWrapper>
                    <Imagewrapper style={{}}>
                        <Imagebox style={{}}>
                            <Image src={pickdate.default} />
                        </Imagebox>

                    </Imagewrapper>
                </CalendarImagebox>
                <Box>
                    <InputWrapper>
                        <ContactusCustomLabel>{configJSON.pickuptext}</ContactusCustomLabel>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder="Any details you would like to share?"
                            type="time"
                            data-test-id="selecttimeid"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => this.setState({ time: e.target.value })}
                            name="Message"
                            value={this.state.time}

                            InputProps={{
                                style: {
                                    borderRadius: '8px',
                                    background: "white",
                                    paddingLeft: "24px"
                                },
                                inputProps: {
                                    style: {
                                        color: "#A6B0BF",
                                        fontSize: '14px',
                                        fontWeight: 400,
                                    },
                                }
                            }}
                            sx={{
                                "& .MuiInputBase-input::placeholder": {
                                    color: "#A6B0BF", fontFamily: "Inter", fontSize: "16px", fontWeight: 400
                                },
                            }}
                        />
                        <img src={timer.default} style={webstyle.timeimgae} />
                    </InputWrapper>
                </Box>

            </Choosedatetimewraaper>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webstyle = {
    timeimgae: {
        position: "absolute" as "absolute",
        left: "12px",
        top: "13px"
    }
}
// Customizable Area End
