
// Customizable Area Start
import React from "react";
import ChooseFromMapController, { Props } from "./ChooseFromMapController.web";
import {orderSummery,bro1,pickdrop,avatar,map,summery} from "../assets";
// Customizable Area End

// Customizable Area Start
import NavigationMenu from "../../../navigationmenu/src/NavigationMenu.web";
import CloseIcon from '@mui/icons-material/Close';
import {
  Typography,
  Button,
  Box,
  styled,Grid,  
} from "@mui/material";

    const ColumnBox = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  }));

  const StyledDiv = styled('div')(({ theme }) => ({
    padding: '0.625%',
    display:'flex',
    alignItems:'center',
    paddingTop:'25px',
    paddingLeft:'3%',
    [theme.breakpoints.down('sm')]: {
      padding: '5px', 
    },
  }));
  
  const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: '26px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem', // Adjust font size for smaller screens if necessary
    },
  }));
 
  
  const ImageWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });
  
  const OrderSummaryText = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    color:'#0F172A',
    fontWeight: 600,
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  }));
  
  const TripDetailsText = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    marginBottom: '10px',
    color:'#475569',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  }));
  const TripDetailsText2Bold = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    marginBottom: '5px',
    color:'#0F172A',
    fontWeight:700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  }));
  const TripDetailsText2 = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    color:'#475569',
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  }));
  
  const TripDetailsContainer = styled(Grid)(({ theme }) => ({
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
    },
  }));
  
  const RatingContainer = styled('div')({
    display: 'flex',
    flexDirection:'column',
    marginBottom: '10px',
  });
  
  const ReviewContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:'flex-start',
    padding: '10px',
    borderRadius: '8px',
    marginBottom: '10px',
    backgroundColor:'#F1F5F9',
    [theme.breakpoints.down('sm')]: {
    
    },
  }));
  const StyledButton = styled(Button)(({ theme }) => ({
    marginTop:'20px',
    backgroundColor: '#1D6FB8',
    fontSize:'16px',
    width:'40%',
    fontWeight:700,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    color: 'white',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#303f9f',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px', 
    },
  }));
  const Typography1 = styled(Typography)(({ theme }) => ({
    fontSize: '16px', 
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px', 
    },
  }));
  
  const Typography2 = styled(Typography)(({ theme }) => ({
    fontSize: '14px', 
    fontWeight: 600,
    paddingBottom:'5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px', 
    },
  }));
  
  const Typography3 = styled(Typography)(({ theme }) => ({
    fontSize: '12px', 
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  }));
  const FilledStar = styled('span')({
    color: '#87C122', 
    fontSize: '24px',
  });
  
  const EmptyStar = styled('span')({
    color: 'gray', 
    fontSize: '24px',
  });
  const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh', // Full viewport height
    backgroundColor:'#ECFDF5'
  });
  const LeftColumn = styled('div')({
    flex: 1,
    overflowY: 'auto', // Allow scrolling
    backgroundColor: 'white',
    marginRight:'3%',
    borderRadius: '8px',
    scrollbarWidth: "none",
    msOverflowStyle: "none", 
    '&::-webkit-scrollbar': { 
      display: "none"
    }
  });
  
  const ScrollableContent = styled('div')({
   
  });
  
  const RightColumn = styled('div')({
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  });
  
  const TopRightDiv = styled('div')({
    height: '70%',
    overflow: 'hidden',
  });
  
  const BottomRightDiv = styled('div')({
    height: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  });
  
  const Image = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  });
// Customizable Area End

export default class ChooseFromMap extends ChooseFromMapController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    StarRating = (rating:any ,totalstar:any) => {
      return (
        <div>
          
          {[...Array(rating)].map((_, index) => (
            <FilledStar key={index} style={{paddingRight:'10px'}} data-test-id="FilledStar">★</FilledStar>
          ))}
         
          {[...Array(totalstar - rating)].map((_, index) => (
            <EmptyStar key={index} style={{paddingRight:'10px'}} data-test-id="EmptyStar">☆</EmptyStar>
          ))}
        </div>
      );
    };
    // Customizable Area End

    render() {

        // Customizable Area Start
        const MainContent = styled('div')(({ theme }) => ({
          display: 'flex',
          flexDirection: 'row',
          padding:'3%',
          paddingTop:'0px',
          height: this.state.availableHeight, // Adjust height based on header and content height
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column', // Change to column layout on small screens
            height: 'auto',
          },
        }));
        // Customizable Area End
        return (
            // Customizable Area Start
          <Container>
            <div ref={this.headerRef}>
              <NavigationMenu navigation={this.props.navigation} id={""} />
            </div>

            <StyledDiv ref={this.orderSummaryRef}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px' }}>
                <img src={orderSummery.default} alt="orderSummery" className="icon" onClick={this.navigateToProjectTemplate} style={{ objectFit: 'contain', width: '20px', paddingBottom: '5px', cursor: 'pointer' }} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledTypography >Order Summary</StyledTypography>
              </div>
            </StyledDiv>

            <MainContent>
              <LeftColumn>
                <ScrollableContent>
                  <ColumnBox>
                    <Grid item xs={12}>
                      <ImageWrapper>
                        <img src={bro1.default} alt="Order" style={{ maxWidth: '100%', height: 'auto' }} />
                      </ImageWrapper>
                    </Grid>

                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingTop: '20px', paddingBottom: '20px' }}>
                      <OrderSummaryText>Your Ride is scheduled</OrderSummaryText>
                      <TripDetailsText>Mon 22 Aug, 4:50 PM - 5:00 PM</TripDetailsText>
                    </Grid>

                    <Grid item xs={12}>
                      <OrderSummaryText>Trip Details</OrderSummaryText>

                      <TripDetailsContainer container >
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                          <img src={pickdrop.default} alt="Drop Off" style={{ height: '80%' }} />

                        </Grid>
                        <Grid item xs={10} style={{}}>
                          <div>
                            <TripDetailsText2Bold>Pick Up From</TripDetailsText2Bold>
                            <TripDetailsText2>Maple Street, Springfield</TripDetailsText2>
                          </div>
                          <div>
                            <TripDetailsText2Bold>Drop Off At</TripDetailsText2Bold>
                            <TripDetailsText2>Avenue, Lincoln Park, Chicago</TripDetailsText2>
                          </div>

                        </Grid>
                      </TripDetailsContainer>
                    </Grid>

                    <Grid item xs={12} style={{ paddingBottom: '20px', fontSize: '14px', }}>
                      <div>
                        <TripDetailsText2Bold>Type</TripDetailsText2Bold>
                        <TripDetailsText2>Sedan</TripDetailsText2>
                      </div>
                      <div>
                        <TripDetailsText2Bold>Fixed cost:</TripDetailsText2Bold>
                        <TripDetailsText2>$300</TripDetailsText2>
                      </div>
                    </Grid>

                    <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                      <RatingContainer>
                        <Typography style={{ paddingBottom: '15px', fontWeight: 700 }}>Your Rating</Typography>
                        {this.StarRating(4, 5)}
                      </RatingContainer>
                    </Grid>

                    <Grid item xs={12}>
                      {this.state.showChat &&
                        (<ReviewContainer onClick={this.navigateToChat}>
                          <img src={avatar.default} alt="Lock Icon" className="icon" style={{ objectFit: 'contain', width: '10%', paddingBottom: '5px', paddingRight: '10px' }} />
                          <div style={{ paddingRight: '20%', }}>
                            <Typography1>Jane Deo</Typography1>
                            <Typography2>A message from the driver.</Typography2>
                            <Typography3 style={{ width: '90%', color: '#475569' }}>Thank you for following safety control on this trip. Have a great day</Typography3>

                          </div>
                          <CloseIcon style={{ cursor: 'pointer', width: '4%' }} onClick={this.closeChat} />
                        </ReviewContainer>)
                      }

                    </Grid>

                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <StyledButton variant="contained" onClick={this.navigateToProjectTemplate}>Done</StyledButton>
                    </Grid>
                  </ColumnBox>
                </ScrollableContent>
              </LeftColumn>

              {/* Right Column */}
              <RightColumn>
                <TopRightDiv>
                  <Image
                    src={map.default}
                    alt="Image 1"
                    style={{borderRadius:'10px' }}
                  />
                </TopRightDiv>
                <BottomRightDiv>
                  <Image
                    src={summery.default}
                    alt="Image 2"
                    style={{ objectFit: 'contain' }}
                  />
                </BottomRightDiv>
              </RightColumn>
            </MainContent>
          </Container>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End