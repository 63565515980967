// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";


export const configJSON = require("./config");
export interface ApiCallFunction {
  contentType: string,
  method: string,
  endPoint: string,
  body?: {}
}
interface ErrorResponse {
  errors: Array<{
    detail: string;
  }>;
}
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  passwordCriteria: {
    hasUpperCase: boolean,
    hasLowerCase: boolean,
    hasNumber: boolean,
    hasSpecialChar: boolean,
    isValidLength:boolean
};
showPassword: boolean;
agreeToTerms: boolean;
showApiError: boolean;
apiErrorMessage: string;
btnLoader: boolean;
emailError: boolean;
role:string;
email: string;
password: string;
isAgreedToTerms:boolean;
FNameError:boolean;
LNameError:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SignUpDriverController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountForRiderApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.receive = this.receive.bind(this);


    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      showApiError: false,
      apiErrorMessage: "",
      firstName: "",
      lastName: "",
      emailError: false,
      agreeToTerms: false,
      showPassword: false,
      passwordCriteria: {
          hasUpperCase: false,
          hasLowerCase: false,
          hasNumber: false,
          hasSpecialChar: false,
          isValidLength:false
      },
      btnLoader: false,
      role: "driver",
      email: "",
      password: "",
      isAgreedToTerms:false,
      FNameError:false,
      LNameError:false,
      // Customizable Area Start
    };
  }
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const TermsCondsAccepted = message.getData(
        getName(MessageEnum.TermAndConditionCheck)
      );
      const Inputdata = message.getData(
        getName(MessageEnum.Sendingbackallinputsdata)
      );

      this.setState({
        agreeToTerms: TermsCondsAccepted, 
        firstName: Inputdata.first_name,
        lastName: Inputdata.last_name,
        emailError:Inputdata.emailError,
        passwordCriteria:Inputdata.passwordCriteria,
        password: Inputdata.password,
        role: Inputdata.role_id,
        email: Inputdata.email, 
      })
      
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
     
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createAccountForRiderApiCallId) {

          if (!responseJson.errors) {
            this.setState({ btnLoader: false });
            setStorageData("email", this.state.email);
            localStorage.setItem(
              "token",
              JSON.stringify(responseJson.meta.token)
            );          
            localStorage.setItem(
              "id",
              JSON.stringify(responseJson.data.id)
            );         
            this.navigateTONext2()}
          } 
                this.handleError(responseJson)
            
               
              
           
        
      }

    }
  }
  handleError(responseJson: ErrorResponse) {
    // Check if responseJson and errors exist and errors array is not empty
    if (responseJson && responseJson.errors && responseJson.errors.length > 0) {
      const failedLoginMessage = responseJson.errors[0].detail;
  
      if (failedLoginMessage === "Email has already been taken") {
        this.setState({ showApiError: true, apiErrorMessage: responseJson.errors[0].detail, btnLoader: false });

      } 
    } 
  }
  navigateTONext2=()=>{
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "SignUpDriverNext");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
      
        }
 

  handleSubmitDriverAccount = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ btnLoader: true });
    let body = {
        data: {
            type: "email_account",
            attributes: {
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                email: this.state.email,
                password: this.state.password,
                role_id: "2"
            }
        }
    }

    this.createAccountForRiderApiCallId = await this.apiCallFunctionDriver({
        contentType: configJSON.contentTypeApiAddDetail,
        method: configJSON.post,
        endPoint: configJSON.postAPIDriver,
        body: body
    });

};
handleDriverPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const password = event.target.value;

  let passwordCriteria = {
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasNumber: /\d/.test(password),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      isValidLength: password.length >= 8, 
  };

  this.setState({
      password,
      passwordCriteria,
  });
};
apiCallFunctionDriver = async (data: ApiCallFunction) => {
  const { contentType, method, endPoint, body } = data;
  let token = await getStorageData("tempToken");

  const header = {
      'Content-Type': contentType,
      token,
  }

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  )
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  )
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
  )
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
  )
  body &&
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
      )

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};
handleRoleChangeDriver = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ role: event.target.value });
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "SignUpRider");
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
};
handleChangeForFNameDriver = (event: any) => {
  const { name, value } = event.currentTarget;

  // Sanitize the input to allow only letters (a-z, A-Z)
  const sanitizedValue = value.replace(/[^a-zA-Z]/g, '');

  // Check if the sanitized value meets the min/max length requirements
  const isValidLength = sanitizedValue.length >= 2 && sanitizedValue.length <= 30;

  // Update the state with dynamic property and the error flag
  this.setState(prevState => ({
    ...prevState,
    [name]: sanitizedValue,  // Update firstName dynamically
    FNameError: !isValidLength // Set error based on validity
  }));
}

handleChangeForLNameDriver = (event: any) => {
  const { name, value } = event.currentTarget;

  // Sanitize the input to allow only letters (a-z, A-Z)
  const sanitizedValue = value.replace(/[^a-zA-Z]/g, '');

  // Check if the sanitized value meets the min/max length requirements
  const isValidLength = sanitizedValue.length >= 2 && sanitizedValue.length <= 30;

  // Update the state with dynamic property and the error flag
  this.setState(prevState => ({
    ...prevState,
    [name]: sanitizedValue,  // Update lastName dynamically
    LNameError: !isValidLength // Set error based on validity
  }));
}

handleEmailChangeDriver = (event: React.ChangeEvent<HTMLInputElement>) => {
  const email = event.target.value;
  const emailError = !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email);
  this.setState({
      email,
      emailError,
  });
};
handleClickShowPasswordDriver = () => {
  this.setState({
      showPassword: !this.state.showPassword,
  });
};
handleLoginDriver = () => {
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
}
handleCheckboxChangeDriver = () => {
  this.setState({
      agreeToTerms: !this.state.agreeToTerms,
  });
};
navigateTermscondition = (route: string) => {
  localStorage.removeItem("redirectfrom")
  setStorageData("role", "driver");
  const navigate: Message = new Message(getName(MessageEnum.NavigationTermsandContion));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigate.addData(getName(MessageEnum.AllInputsdataforsignup), {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      role_id: this.state.role,
      emailError:this.state.emailError,
      passwordCriteria:this.state.passwordCriteria,
  });
  this.send(navigate);
}
  // Customizable Area End 
}

