import React from "react";

// Customizable Area Start
import "./EmailAccountRegistratin.css"
import SignUpDriverNextController, {
  Props
} from "./SignUpDriverNextController.web";
import Form2 from '../../../components/src/SignUpdriverStep2.web';
import Form3 from '../../../components/src/SignUpdriverStep3.web';
import Form1 from '../../../components/src/SignUpdriverStep1.web';
import CloseIcon from '@mui/icons-material/Close';
import {
  styled,
  Typography,
  Box,
  IconButton,
  Modal,Dialog,Button
} from '@mui/material';
const configJSON = require("./config");
import { error, retry,sucess } from "./assets";
// Customizable Area End

// Customizable Area Start
const ErrorBox = styled(Box)(({ theme }) => ({
  width: '70%',
  overflow: 'hidden',
  backgroundColor: '#FEE2E2',
  padding: '25px',
  borderRadius: '24.89px',
  borderLeftStyle: 'solid',
  borderLeftColor: '#DC2626',
  borderLeftWidth: '7px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxSizing: 'border-box',
  margin: '0px',
  [theme.breakpoints.down('md')]: {
    width: '80%', // Adjust the width for medium-sized screens
  },
  [theme.breakpoints.down('sm')]: {
    width: '80%', // Adjust for smaller screens
    padding: '20px', // Decrease padding on smaller screens
  },
}));
const ErrorBox2 = styled(Box)(({ theme }) => ({
  width: '35%',
  overflow: 'hidden',
  border:'0px solid white',
  backgroundColor: '#FFFFFF',
  padding: '25px',
  borderRadius: '24.89px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxSizing: 'border-box',
  margin: '0px',
  [theme.breakpoints.down('md')]: {
    width: '70%', // Adjust the width for medium-sized screens
  },
  [theme.breakpoints.down('sm')]: {
    width: '75%', // Adjust for smaller screens
    padding: '20px', // Decrease padding on smaller screens
  },
}));

const BoxCenter = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const ErrorImg = styled('img')(({ theme }) => ({
  width: '289.73px',
  height: '214.93px',
  [theme.breakpoints.down('sm')]: {
    width: '200px', // Adjust the image size for smaller screens
    height: '150px',
  },
}));
const SucessImg = styled('img')({
  width: '289.73px',
  height: '214.93px',
  "@media (max-width: 960px)": {
    width: '200px',
    height: '150px',
  }
});
const TypographyOops = styled(Typography)(({ theme }) => ({
  color: '#DC2626',
  fontSize: '24px',
  marginTop: '30px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px', // Decrease font size on smaller screens
  },
}));
const TypographySucess= styled(Typography) ({
  color: 'var(--Color-1, #87C122)',
  fontSize: '30px',
  fontWeight:'700',
  marginTop: '30px',
  "@media (max-width: 960px)": {
    fontSize: '20px',
  }
});
const TypographyDouble = styled(Typography)({
  color: '#94A3B8',
  marginTop: '30px',
  fontSize: '24px',
  textAlign: 'center',
  "@media (max-width: 960px)": {
    fontSize: '20px',
    marginTop: '20px',
  }
});
const TypographyDouble2 = styled(Typography)({
  color: '#475569',
  marginTop: '30px',
  fontSize: '22px',
  fontWeight:'400',
  textAlign: 'center',
  "@media (max-width: 960px)": {
    fontSize: '20px',
    marginTop: '20px',
  }
});
const TypographyDouble4 = styled(Typography)({
  color: '#475569',
  marginTop: '8px',
  fontSize: '22px',
  fontWeight:'400',
  textAlign: 'center',
  "@media (max-width: 960px)": {
    fontSize: '20px',
    marginTop: '20px',
  }
});
const ButtonReturn = styled(Box)(({ theme }) => ({
  background: '#DC2626',
  ':hover': {
  },
  color: '#FFFFFF',
  width: '40%',
  height: '79.07px',
  borderRadius: '16.53px',
  marginTop: '45px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor:'pointer',
  [theme.breakpoints.down('sm')]: {
    width: '80%', // Increase width for smaller screens
    height: '60px', // Decrease button height for smaller screens
  },
}));
const ButtonReturn2 = styled(Box)(({ theme }) => ({
  background: '#1D6FB8',
  '&:hover': {
    backgroundColor: '#559ADC',
  },
  color: '#ffff',
  width: '40%',
  height: '54px',
  borderRadius: '8px',
  marginTop: '45px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '25%', // Increase width for smaller screens
    height: '34px', // Decrease button height for smaller screens
  },
}));
const TypographyRetry = styled(Typography)({
  fontSize: '30px',
  marginTop: '5px',
  "@media (max-width: 960px)": {
    fontSize: '24px',
  }
});
const TypographyRetry2 = styled(Typography)({
  fontSize: '14px',
  fontWeight:'400',
  marginTop: '5px',

  "@media (max-width: 960px)": {
    fontSize: '14px'
  }
});
const ImgRetry = styled('img')({
  width: '50px',
  height: '50px',
  margin: '0px 10px',
  "@media (max-width: 960px)": {
    width: '40px',
    height: '40px',
  }
});
const StyledDialogContent = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '25px',
  backgroundColor: '#FFFFFF',
  borderRadius: '24.89p',
  position: 'relative',
  margin: '0 auto',
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    padding: '20px', // Decrease padding on smaller screens
  },
}));

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 10,
  color: 'grey',
}));

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const SuccessImage = styled('img')({
  width: '289.73px',
  height: '214.93px',
  "@media (max-width: 960px)": {
    width: '200px',
    height: '150px',
  }
});

const SuccessTitle = styled(Typography)({
  color: '#87C122',
  fontSize: '30px',
  fontWeight: '700',
  marginTop: '30px',
  textAlign: 'center',
  "@media (max-width: 960px)": {
    fontSize: '20px',
  }
});

const MessageText = styled(Typography)({
  color: '#475569',
  marginTop: '8px',
  fontSize: '22px',
  fontWeight: '400',
  textAlign: 'center',
  "@media (max-width: 960px)": {
    fontSize: '20px',
    marginTop: '20px',
  }
});

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#1D6FB8',
  '&:hover': {
    backgroundColor: '#559ADC',
  },
  color: '#ffffff',
  width: '40%',
  height: '54px',
  borderRadius: '8px',
  marginTop: '45px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '60%',
    height: '34px',
  },
}));

const ButtonText = styled(Typography)({
  fontSize: '14px',
  fontWeight: '400',
  marginTop: '5px',
  "@media (max-width: 960px)": {
    fontSize: '14px',
  }
});
// Customizable Area End

export default class SignUpDriverNext extends SignUpDriverNextController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start 
  renderForm = () => {
    const { step } = this.state;
  
    switch (step) {
      case 1:
        return  <Form1 Submit1={this.Submit1} initialValues={this.state.initialValues1} back={this.handleBack}/>;
      case 2:
        return <Form2 Submit1={this.Submit1} initialValues={this.state.initialValues2} back={this.handleBack}/>
      case 3:
        return  <Form3 Submit1={this.Submit1} initialValues={this.state.initialValues3} back={this.handleBack}/>;
      default:
        return <h2>All forms completed!</h2>;
    }
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (

       <div style={{overflowX:'hidden'}}>{this.renderForm()}
        <Dialog
        open={this.state.driverOpenSucessModal}
        onClose={this.handleSucessClosee}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        data-testId={'closeSucessModalID'}
        fullWidth={true}
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: '16px', // Set border-radius using PaperProps
          },
        }}
      >
        <StyledDialogContent>
          <StyledCloseButton
            aria-label="close"
            onClick={this.handleSucessClosee}
          >
            <CloseIcon />
          </StyledCloseButton>
          <ContentWrapper>
            <SuccessImage src={sucess.default} alt="success" />
            <SuccessTitle data-testId={"emptyErrorId"}>{configJSON.sucessAbove}</SuccessTitle>
            <MessageText>{configJSON.Sucess1}</MessageText>
            <MessageText>{configJSON.Sucess2}</MessageText>
            <MessageText>{configJSON.Sucess3}</MessageText>

            <ActionButton data-testId={"handleSuccessID"} onClick={this.handleSucessClosee}>
              <ButtonText>OK</ButtonText>
            </ActionButton>
          </ContentWrapper>
        </StyledDialogContent>
      </Dialog>
        <Modal
          open={this.state.driverOpenErrorModal}
          onClose={this.handleErrorClosee}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          data-testId={'closeOopsModalID'}
        >
          <ErrorBox>
            <BoxCenter>
              <ErrorImg src={error.default} alt="error" />
              <TypographyOops data-testId={"emptyErrorId"}>{configJSON.oops}</TypographyOops>
              <TypographyDouble>{configJSON.double}</TypographyDouble>
              <ButtonReturn onClick={this.handleErrorClosee} data-testId="closeOopsButtonID">
                <ImgRetry src={retry.default} alt="retry" />
                <TypographyRetry>{configJSON.retry}</TypographyRetry>
              </ButtonReturn>
            </BoxCenter>
          </ErrorBox>
        </Modal>

       </div>
    );
    // Customizable Area End
  }


}


  // Customizable Area Start
  const webStyle = {
};
  // Customizable Area End
