// Customizable Area Start
import React from "react";
import UpcomingRidesController, { Props } from "./UpcomingRidesController.web";
import {
    Typography,
    Box,
    styled
} from "@mui/material"
import { edit, expandicon, deleteicon } from "../assets";
import { configJSON } from "../ProjectTemplatesController";


// Customizable Area End

// Customizable Area Start
const Upcomingridesstatsbox = styled(Box)({
    width: "70%",
    height: "100px",
    borderRadius: "16px",
    borderLeft: "5px solid #87C122",
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    background: "white",
    position: 'relative',
    "@media (max-width: 1300px)": {
        width: "80%",
    },
    "@media (max-width: 700px)": {
        width: "90%",
    },
    "@media (max-width: 535px)": {
        flexDirection: "column",
        height: "auto"
    },
});

const Editcancelbox = styled(Box)({
    display: "flex",
    gap: "10px",
    borderRadius: "8px",
    top: "0",
    position: "absolute" as "absolute",
    height: "83px",
    left: "101%",
    paddingTop: "12px",
    width: "163px",
    flexDirection: "column" as "column",
    background: "#FFFFFF",
    border: "1px solid #F5F5F5",
    "@media (max-width: 930px)": {
        top: "52px",
        left: "80%",
    },
    "@media (max-width: 630px)": {
        top: "52px",
        left: "70%",
    },
    "@media (max-width: 535px)": {
        top: "158px",
        left: "30%",
    },
});

const UpcomingridesBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    "@media (max-width: 630px)": {
        paddingTop: "30px"
    },
});


// Customizable Area End

export default class UpcomingRides extends UpcomingRidesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <UpcomingridesBox >
                {this.state.upcomingridesdata.map((item: any, index: any) => {
                    return (
                        <Upcomingridesstatsbox >
                            <Box>
                                <Typography style={{ ...webstyle.headingtext, paddingTop: "3px" }}>{item.datetime}</Typography>
                            </Box>
                            <Box style={webstyle.ucomingrideinfobox}>
                                <Typography style={webstyle.headingtext}>
                                    {configJSON.from}
                                </Typography>
                                <Typography style={webstyle.datatext}>
                                    {item.from}
                                </Typography>
                            </Box>
                            <Box style={webstyle.ucomingrideinfobox}>
                                <Typography style={webstyle.headingtext}>
                                    {configJSON.to}
                                </Typography>
                                <Typography style={webstyle.datatext}>
                                    {item.to}
                                </Typography>
                            </Box>
                            <Box style={webstyle.ucomingrideinfobox}>
                                <Typography style={webstyle.headingtext}>
                                    {configJSON.price}
                                </Typography>
                                <Typography style={webstyle.datatext}>
                                    {item.price}
                                </Typography>
                            </Box>
                            <Box style={webstyle.expandicon}>
                                <img src={expandicon.default} style={{ paddingTop: "12px" }} onClick={() => this.setState({ openModalIndex: index })} data-test-id="expandpopupid" />
                            </Box>
                            {this.state.openModalIndex === index && (
                                <Editcancelbox >
                                    <Box style={webstyle.editinsidebox

                                    } >
                                        <img src={edit.default} />
                                        <Typography style={webstyle.edittext}>{configJSON.edit}</Typography>
                                    </Box>
                                    <Box style={webstyle.editinsidebox} onClick={() => this.setState({ openModalIndex: undefined })}>
                                        <img src={deleteicon.default} />
                                        <Typography style={webstyle.edittext}>{configJSON.cancel}</Typography>
                                    </Box>
                                </Editcancelbox>
                            )}
        
                        </Upcomingridesstatsbox>
                    );
                })}
            </UpcomingridesBox>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webstyle = {
    headingtext: {
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "18px",
        color: "#0F172A",
        lineHeight: "26px",
    },

    

    expandicon: {
        width: "6%",
        display: "flex",
        justifyContent: "end",
        height: "26px"
    },

    edittext: {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        color: "##0F172A",
        lineHeight: "24px",
    },

    editcancelbox: {
        display: "flex",
        gap: "10px",
        borderRadius: "8px",
        top: "0",
        position: "absolute" as "absolute",
        height: "83px",
        left: "101%",
        paddingTop: "12px",
        width: "163px",
        flexDirection: "column" as "column",
        background: "#FFFFFF",
        border: "1px solid #F5F5F5"
    },

    editinsidebox: {
        cursor: "pointer",
        display: "flex",
        paddingLeft: "12px",
        gap: "8px"
    },

    datatext: {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "18px",
        color: "#0F172A",
        lineHeight: "26px",
    },

    ucomingrideinfobox: {
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "space-around"
    }
}
// Customizable Area End
