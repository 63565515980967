import React from 'react';
// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";

import {choseedate,appointment,map}  from "./assets";
import {
  
  Button,
  Box,
  styled
} from "@mui/material";
import "../../email-account-registration/src/EmailAccountRegistratin.css"

// Customizable Area End

import AppointmentsController, {
  Props,
  configJSON,
} from './AppointmentsController';
import { TimeSlot } from './types';

export default class Appointments extends AppointmentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  buttonTabs = () => {
    return (
      <>
       <Tabbox>
        <Buttonbox>
          <Singlebuttonbox>
            <Button style={this.state.activeTab === '1' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("1")} data-test-id="choosedateid">
              <img src={choseedate.default} style={webstyle.choosedateimage} /> All
            </Button>
          </Singlebuttonbox>
          <Singlebuttonbox>
            <Button style={this.state.activeTab === '2' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("2")} data-test-id="planyourrideid">Scheduled</Button>
          </Singlebuttonbox>
          <Singlebuttonbox>
            <Button style={this.state.activeTab === '3' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("3")} data-test-id="upcomingrideid">Completed</Button>
          </Singlebuttonbox>
          <Singlebuttonbox>
            <Button style={this.state.activeTab === '4' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("4")} data-test-id="upcomingrideid">Cancelled</Button>
          </Singlebuttonbox>
        </Buttonbox>
        <ContentContainer>
          <ContentBox>
            {this.screentab()}
          </ContentBox>
        </ContentContainer>
      </Tabbox>
      </>

    )
  }

  screentab = () => {
    switch (this.state.activeTab) {
      case '1':
       return <div className='firstdiv'>{this.renderTable("1")}</div>

      case '2':
        return <div className='firstdiv'>{this.renderTable("2")}</div>
      case '3':
        return <div className='firstdiv'>{this.renderTable("3")}</div>
        case '4':
          return <div className='firstdiv'>{this.renderTable("4")}</div>
      default:
        return <div className='firstdiv'>{this.renderTable("5")}</div>
    }
  }

  renderTable = (currenttab:string) => {   
    let sampleData = this.getSampleData(currenttab); 
    return (

      <div style={{padding:'20px',backgroundColor:'white',borderRadius:'20px',}}>
    <div className="table-container">
  <table className="custom-table">
    <thead>
      <tr className="header-row">
        <th>Job ID</th>
        <th>Pickup Location</th>
        <th>Destination</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
      <br />
    </thead>
    <tbody>
    {sampleData.map((row:any) => (
      <>
      <tr className="content-row">
        <td>{row.JobID}</td>
        <td>{row.PickupLocation}</td>
        <td>{row.Destination}</td>
        <td><span style={{backgroundColor:this.getCellBackgroudColor(row.Status),padding:'8px',borderRadius:'20px',fontWeight:'700',color:this.getCellTextColor(row.Status),fontSize:'12px'}}>{row.Status}</span></td>
        <td><span style={{backgroundColor:'#1D6FB8',padding:'8px',borderRadius:'13px',fontWeight:'400',color:'white',width:'100%',display:'inline-block'}}>{row.Actions}</span></td>
      </tr>
      <br />
      </>
    ))}
    </tbody>
  </table>
</div>
    </div>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { appointmentsList } = this.state;

    return (
      <div style={webstyle.AppointmentOuter} className='outerTabs'>
        <NavigationMenu navigation={this.props.navigation} id={""} index={1}/>
        <Box style={{ paddingTop: "40px", display: 'flex', justifyContent: 'center' ,marginBottom:'30px'}}>
          <OuterContainer>
            {this.buttonTabs()}
            <ImageBox>
            <ResponsiveImage src={appointment.default} alt="Right side image" />
            </ImageBox>
          </OuterContainer>
         
        </Box>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', marginBottom: '20px'
        }}>

       <ResponsiveImageMap src={map.default} alt="Right side image" />        
       </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ResponsiveImageMap = styled('img')(({ theme }) => ({
  width: '95%',
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '90%', // 90% width for small screens
  },
  [theme.breakpoints.up('md')]: {
    width: '95%', // 95% width for medium and larger screens
  },
}));

const ResponsiveImage = styled('img')(({ theme }) => ({
  width: '80%',
  height: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '60%',  // Adjust width on medium screens and below
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',  // Full width on small screens
    height:'100%'
  },
}));
const Singlebuttonbox = styled(Box)({
  width: "130px",
  display: "flex",
  justifyContent: 'center',
  alignItems: "center",
});
const ContentContainer = styled(Box)({
  display: "flex",
  flexDirection: "column", // Arrange content in a column
  marginTop: "20px", // Space between the tab bar and the content
});
const OuterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '95%',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column', 
    justifyContent: 'space-evenly',
    height:'auto'
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  flex: '0 0 30%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '20px',
  overflow: 'hidden',
  maxHeight: '100%',
  [theme.breakpoints.down('sm')]: {
    flex: '0 0 100%', // Take full width on small screens
    marginLeft: '0px', // Remove left margin
    maxHeight: '25%',

  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: '20px',
  [theme.breakpoints.down('sm')]: {
    padding: '10px', // Adjust padding for small screens
  },
}));

const Tabbox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column", 
  [theme.breakpoints.down('sm')]: {
    width: "100%", // Full width on small screens
  },
}));

const Buttonbox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "65px",
  display: "flex",
  paddingLeft: "10px",
  paddingRight: "10px",
  background: "#FFFFFF",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "16px",
  [theme.breakpoints.down('sm')]: {
    height: "50px", // Adjust height for small screens
    paddingLeft: "5px", 
    paddingRight: "5px",
  },
}));


const webstyle = {
  AppointmentOuter:{  margin: '0px', padding:'0px', width: '100vw', height: '100vh', },
  activeButoon: {
    width: "100%",
    background: "#ECFDF5",
    borderRadius: "50px",
    border:'1px solid #87C122',
    color: "#0F172A",
    height: "35px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "capitalize" as "capitalize",
    whiteSpace: "nowrap"
  },

  notactiveButton: {
    color: "#475569",
    height: "35px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "capitalize" as "capitalize",
    whiteSpace: "nowrap"
  },

  tabbox: {
    width: "100%",
    display: "flex",
    height: "100px"
  },

  buttonbox: {
    width: "70%",
    height: "72px",
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
    background: "#FFFFFF",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "16px"
  },

  singlebuttonbox: {
    width: "355px",
    display: "flex",
    justifyContent: 'center',
    alignItems: "center"
  },

  choosedateimage: {
    width: "20px",
    height: "20px",
    paddingRight: "5px"
  }

}
// Customizable Area End
