import React from "react";

// Customizable Area Start
import { Box, Typography, Button, styled } from "@mui/material";
import { backbutton, Termsservices } from "./assets";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box style={webstyle.termsconditioncontainer}>

        <img src={backbutton.default} style={webstyle.backbutton} onClick={() => this.navigateToSignupDriver("back")} data-test-id="backbuttonid"/>
        <Termsconditionbox >
          <Box style={webstyle.termconditionimagebox}>
            <Termcondtiontext>Terms And Conditions</Termcondtiontext>
            <Termsseriveimage>
              <img src={Termsservices.default} style={{ width: "100%" }} />
            </Termsseriveimage>
          </Box>
          {this.state.termsCondsList?.map((item, index) => (
            <Typography key={index} style={webstyle.termconditiondiscriptiontext}>{item.description.split('\n').map((line, idx) => (
              <React.Fragment key={idx}>
                {line}<br /><br />
              </React.Fragment>
            ))}
            </Typography>
          ))}
          <Box style={webstyle.checkboxcontainer}>
            <label> <input type="checkbox" style={this.state.isChecked ? { ...webstyle.checkbox, ...webstyle.checkboxChecked } : webstyle.checkbox} onChange={this.checkboxChange} />
              {this.state.isChecked && <CheckboxCheckmark>✓</CheckboxCheckmark>}</label>

            <Typography style={webstyle.agreeandtermstext}>I have read and agree to these Terms and Conditions</Typography>
          </Box>
          <Typography style={webstyle.accepttermstext}> Please accept the Terms and Conditions to continue.</Typography>
          <Box onClick={() => this.navigateToSignupDriver("agree")} style={webstyle.agreebuttonbox} data-test-id="agreebuttonid">
            <Button style={webstyle.agreetextcolor}>Agree</Button>
          </Box>
          <Box onClick={() => this.navigateToSignupDriver("cancel")} style={webstyle.cancelbuttonbox} data-test-id="cancelbuttonid">
            
            <Button style={webstyle.canceltextcolor} >Cancel</Button>
          </Box>
        </Termsconditionbox>

      </Box>
    )
    // Customizable Area End
  }
}

// Customizable Area Start

const Termsconditionbox = styled(Box)({
  width: "491px",
  display: "flex",
  flexDirection: "column" as "column",
  justifyContent: "center",
  gap: "20px",
  paddingBottom:"15px",
  "@media (max-width: 518px)": {
    width: "90%",
  },
})

const CheckboxCheckmark = styled(Box)({
  content: '"✓"',
  position: "absolute" as "absolute",
  top: "0px",
  left: "4px",
  fontSize: "18px",
  color: "#FFFFFF",
  cursor: "pointer",
  '@media (max-width: 458px)': {
    top: "10px"
  },
})

const Termcondtiontext = styled(Box)({
  fontFamily: "Inter",
  fontSize: "30px",
  fontWeight: 400,
  lineHeight: "40px",
  color: "#1E293B",
  "@media (max-width: 500px)": {
    fontSize: "25px",
  },
  "@media (max-width: 430px)": {
    fontSize: "20px",
  },
  "@media (max-width: 450px)": {
    fontSize: "18px",
  },
  "@media (max-width: 350px)": {
    fontSize: "16px",
  },
})


const Termsseriveimage = styled(Box)({
  width: "100%",
  "@media (max-width: 518px)": {
    width: "100%",
  },
})

const webstyle = {
  termsconditioncontainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative" as "relative",
  },

  backbutton: {
    position: "absolute" as "absolute",
    left: "20px",
    top: "5px"
  },

  termsconditionbox: {
    width: "491px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    gap: "20px"
  },

  termconditionimagebox: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    marginTop: "10px"
  },

  termcondtiontext: {
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 400,
    lineHeight: "40px",
    color: "#1E293B"
  },

  termconditiondiscriptiontext: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#0F172A"
  },

  agreeandtermstext: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#1E293B"
  },

  accepttermstext: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#DC2626"
  },

  agreebuttonbox: {
    background: "#1D6FB8",
    color: "black",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    height: "56px",
    textTransform:"capitalize" as 'capitalize'
  },

  cancelbuttonbox: {
    background: "",
    color: "black",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid  #1D6FB8",
    borderRadius: "8px",
    height: "56px"
  },

  agreetextcolor: {
    color: "#ECFDF5",
    textTransform:"capitalize" as 'capitalize',
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "40px",
  },

  canceltextcolor: {
    color: "#1D6FB8",
    textTransform:"capitalize" as 'capitalize',
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "40px",
  },

  checkboxcontainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "-3px",
    gap: "5px",
    position: "relative" as "relative"
  },

  checkbox: {
    width: "20px",
    height: "20px",
    borderRadius: "6px",
    border: "1px solid #64748B",
    appearance: "none" as "none",
    cursor: "pointer"
  },

  checkboxCheckmark: {
    content: '"✓"',
    position: "absolute" as "absolute",
    top: "1px",
    left: "8px",
    fontSize: "18px",
    color: "#FFFFFF",
    cursor: "pointer",
    '@media (max-width: 500px)': {
      top: "3px"
    },
  },

  checkboxChecked: {
    backgroundColor: "#1D6FB8",
    width: "20px",
    height: "20px",
    borderRadius: "6px",
    border: "1px solid #64748B",
    appearance: "none" as "none",
    cursor: "pointer"
  },
}
// Customizable Area End
