// @ts-nocheck
import React from "react";

// Customizable Area Start
import PlanYourRide from "./planyourride/PlanYourRide.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ChooseDatesTime from "./choosedate/ChooseDatesTime.web";
import UpcomingRides from "./upcomingrides/UpcomingRides.web";
import { choseedate } from "./assets";
import {
  Typography,
  // Customizable Area Start
  Container,
  Button,
  Box,
  styled
  // Customizable Area End
} from "@mui/material";


// Customizable Area End

import ProjectTemplatesController, {
  Props,
  configJSON,
} from "./ProjectTemplatesController";

export default class ProjectTemplates extends ProjectTemplatesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  buttonTabs = () => {
    return (
      <>
        <Tabbox >
          <Buttonbox >
            <Singlebuttonbox >
              <Button style={this.state.activeTab == '1' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("1")} data-test-id="choosedateid">
                <img src={choseedate.default} style={webstyle.choosedateimage} /> Plan Your ride</Button>
            </Singlebuttonbox>
            <Singlebuttonbox >
              <Button style={this.state.activeTab == '2' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("2")} data-test-id="planyourrideid">Choose dates</Button>
            </Singlebuttonbox>
            <Singlebuttonbox>
              <Button style={this.state.activeTab == '3' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("3")} data-test-id="upcomingrideid">Upcoming Rides</Button>
            </Singlebuttonbox>
          </Buttonbox>
        </Tabbox>
      </>

    )
  }

  screentab = () => {
    switch (this.state.activeTab) {
      case '1':
        return <PlanYourRide />

      case '2':
        return <ChooseDatesTime />

      case '3':
        return <UpcomingRides />

      default:
        return <PlanYourRide />
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={{ boxSizing: 'border-box', margin: 0, padding: 0, width: '100vw', height: '100vh', overflowX: 'hidden' }}>
        <NavigationMenu navigation={this.props.navigation} id={""} index={0} />
        <Box style={{ paddingTop: "40px", paddingLeft: "40px" }}>
          {this.buttonTabs()}
          {this.screentab()}
        </Box>

      </div>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Buttonbox = styled(Box)({
  width: "70%",
  height: "72px",
  display: "flex",
  paddingLeft: "10px",
  paddingRight: "10px",
  background: "#FFFFFF",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "16px",
  "@media (max-width: 800px)": {
    width: "80%",
  },
  "@media (max-width: 700px)": {
    width: "90%",
  },
  "@media (max-width: 575px)": {
    width: "90%",
    flexDirection: "column",
    height: "150px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
});

const Tabbox = styled(Box)({
  width: "100%",
  display: "flex",
  height: "100px",
  "@media (max-width: 575px)": {
    height: "auto",
    width: "92%"
  },
});


const Singlebuttonbox = styled(Box)({
  width: "355px",
  display: "flex",
  justifyContent: 'center',
  alignItems: "center",
  "@media (max-width: 430px)": {
    width: "300px",
  },
  "@media (max-width: 380px)": {
    width: "250px",
  },
});




const webstyle = {
  activeButoon: {
    width: "100%",
    background: "#87C122",
    borderRadius: "50px",
    color: "#0F172A",
    height: "40px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "capitalize" as "capitalize",
    whiteSpace: "nowrap"
  },

  notactiveButton: {
    color: "#475569",
    height: "40px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "capitalize" as "capitalize",
    whiteSpace: "nowrap"
  },

  tabbox: {
    width: "100%",
    display: "flex",
    height: "100px"
  },

  buttonbox: {
    width: "70%",
    height: "72px",
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
    background: "#FFFFFF",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "16px"
  },

  singlebuttonbox: {
    width: "355px",
    display: "flex",
    justifyContent: 'center',
    alignItems: "center"
  },

  choosedateimage: {
    width: "20px",
    height: "20px",
    paddingRight: "5px"
  }

}
// Customizable Area End