
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
     time: any;
     selectedDate:any
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ChooseDatesTimeController extends BlockComponent<Props, S, SS> {
    getHelpCentreApiCallId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            
        ];
        this.state = {
            time: "14:30",
            selectedDate:new Date()
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start

        runEngine.debugLog("Message Recieved", JSON.stringify(message));


        // Customizable Area End
    }

    // Customizable Area Start
    handleDateClick = (date:any) => {
        console.log(date,"date")
        this.setState({selectedDate:date});
      };
    // Customizable Area End
}
