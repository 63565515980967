import React from "react";

// Customizable Area Start
import { Box, styled, Typography, IconButton, List,Collapse, ListItemButton, ListItemText ,Switch,FormControlLabel,FormGroup ,SwitchProps } from "@mui/material";
import { rightSideImage,back_Icon,notification,star,swap,update,congrats,location} from "./assets";
import NotificationUIRiderController, {
  Props
} from "./NotificationUIRiderController.web";
import "../../email-account-registration/src/EmailAccountRegistratin.css";
import {  ExpandMore,NavigateNext} from '@mui/icons-material';
const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start


const ContainerRider = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "20px",
  width: "100vw", 
  height: "calc(100vh - 80px)",  
  boxSizing: "border-box",
  position: "relative",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      height: "auto", 
      width: "100%",  
      padding: "0px",
  },
}));

const LeftColumnRider = styled(Box)(({ theme }) => ({
  flex: "0 0 55%",  
  padding: "10px",
  overflowY: "auto",
  [theme.breakpoints.down("md")]: {
      width: "100%",
      flex: "none",
      padding:'0px'
  },
}));

const RightColumnRider = styled(Box)(({ theme }) => ({
  flex: "0 0 45%",  
  padding: "10px",
  position: "relative",
  [theme.breakpoints.down("md")]: {
      width: "100%",
      flex: "none",
      marginTop: "20px",
      height:'500px'
      
  },
}));

const FixedImageContainerRider = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "40%",  
  width: "100%",
  height: "60%",  
  overflow: "hidden",

  [theme.breakpoints.down("md")]: {
    top: "0%",       
    height: "100%",
  },
}));

const ResponsiveTypographyRider = styled(Typography)(({ theme }) => ({
  marginLeft: '45px',
  marginTop: '25px',
  fontSize: '18px',
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0px',   
    marginTop: '15px',   
    fontSize: '16px',    
  },
}));
const ResponsiveListRider = styled(List)(({ theme }) => ({
  marginLeft: '45px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0px', 
  },
}));
const ResponsiveCheckBoxRider= styled(Box)(({ theme }) => ({
  marginLeft: '45px', 
  marginTop:'20px',
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,   
  },
}));
const IOSSwitchRider = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#1D6FB8', // Custom checked color
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
// Customizable Area End

export default class NotificationUIRider extends NotificationUIRiderController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End
  render() {
    // Customizable Area Start
   
    return (

      <ContainerRider>
        <LeftColumnRider>
          <div style={webStyle.OuterContainerRider}>

            <IconButton style={webStyle.IconButtonStyleRider} onClick={this.handleBackToMenu} data-test-id="backButton">
              <img
                src={back_Icon.default} // Replace with your image URL
                alt="Back"
                style={webStyle.marginRightRider} // Add spacing between icon and text
              /> {/* Back Button */}
            </IconButton>

            <p style={webStyle.NotificationTextStyleRider}>
            {configJSON.notificationsTitle}
            </p>
          </div>
          <div>
            <ResponsiveTypographyRider>
            {configJSON.todayLabel}
            </ResponsiveTypographyRider>

            <ResponsiveListRider  >
              <ListItemButton
                onClick={this.handleFirstClickRider}
                sx={webStyle.ButtonStyleRider} data-test-id="FirstButton">


                <ListItemText
                  primary={
                    <span style={webStyle.bigTextRider}>
                      <img
                        src={congrats.default}
                        alt="Icon"
                        style={webStyle.smallImageStyleRider}
                      />
                      {configJSON.rideCompleted}
                    </span>
                  }
                  secondary={
                    <span style={webStyle.ellipseStyleRider}>
                      {configJSON.thanksText}
                    </span>
                  }
                />
                {this.state.openFirstRider ? <ExpandMore /> : <NavigateNext />}
              </ListItemButton>

              <Collapse in={this.state.openFirstRider} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton sx={webStyle.ListItemButtonStyleRider}>

                    <ListItemText primary="Item1" />
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton
                onClick={this.handleSecondClickRider}
                sx={webStyle.secondButtonStyleRider} data-test-id="SecondButton">

                <ListItemText
                  primary={
                    <span style={webStyle.bigTextRider}>
                      <img
                        src={notification.default}
                        alt="Icon"
                        style={webStyle.smallImageStyleRider} />
                      Reminder
                    </span>
                  }
                  secondary={
                    <span style={webStyle.ellipseStyleRider}>
                      Update your app for the latest features.
                    </span>
                  }
                />

                {this.state.openSecondRider ? <ExpandMore /> : <NavigateNext />}
              </ListItemButton>

              <Collapse in={this.state.openSecondRider} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton sx={webStyle.ListItemButtonStyleRider}>
                    <ListItemText primary="Item 2" />
                  </ListItemButton>
                </List>
              </Collapse>
            </ResponsiveListRider>
          </div>
          <div>
            <ResponsiveTypographyRider>
            {configJSON.earlierLabel}
            </ResponsiveTypographyRider>

            <ResponsiveListRider  >
              <ListItemButton
                onClick={this.handleThirdClickRider}
                sx={webStyle.thirdButtonSTyleRider} data-test-id="ThirdButton">


                <ListItemText
                  primary={
                    <span style={webStyle.bigTextRider}>
                      <img
                        src={update.default}
                        alt="Icon"
                        style={webStyle.smallImageStyleRider} />
                     {configJSON.updateLabel}
                    </span>
                  }
                  secondary={
                    <span style={webStyle.ellipseStyleRider}>
                     {configJSON.updateProfilePicture}
                    </span>
                  }
                />
                {this.state.openThirdRider ? <ExpandMore /> : <NavigateNext />}
              </ListItemButton>

              <Collapse in={this.state.openThirdRider} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton sx={webStyle.ListItemButtonStyleRider}>

                    <ListItemText primary="Item1" />
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton
                onClick={this.handleFourthClickRider}
                sx={webStyle.secondButtonStyleRider} data-test-id="FourthButton">

                <ListItemText
                  primary={
                    <span style={webStyle.bigTextRider}>
                      <img
                        src={location.default}
                        alt="Icon"
                        style={webStyle.smallImageStyleRider}
                      />
                      {configJSON.DriverMatched}
                    </span>
                  }
                  secondary={
                    <span style={webStyle.ellipseStyleRider}>
                      {configJSON.GreatNews}
                    </span>
                  }
                />

                {this.state.openFourthRider ? <ExpandMore /> : <NavigateNext />}
              </ListItemButton>

              <Collapse in={this.state.openFourthRider} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton sx={webStyle.ListItemButtonStyleRider}>

                    <ListItemText primary="Item2" />
                  </ListItemButton>
                </List>
              </Collapse>
            </ResponsiveListRider>
          </div>
          <ResponsiveCheckBoxRider >
            <FormGroup>

              <FormControlLabel
                control={<IOSSwitchRider defaultChecked />}
                label={configJSON.enableEmailNotifications}
                sx={{
                  margin: 0, // Remove any default margin
                  alignItems: 'center', // Center align switch and label
                  '& .MuiFormControlLabel-label': {
                    fontSize: '16px',
                    fontWeight: 500,
                    color: '#0F172A', // Label color
                    marginLeft: '8px', // Optional spacing between label and switch
                  },
                }}
              />
            </FormGroup>
          </ResponsiveCheckBoxRider>
        </LeftColumnRider>

        <RightColumnRider>


          <FixedImageContainerRider>
            <img
              src={rightSideImage.default} // Replace with your image URL
              alt="Fixed"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </FixedImageContainerRider>
        </RightColumnRider>
      </ContainerRider>
    );
    // Customizable Area End
  }


}


  // Customizable Area Start
  const webStyle = {
 OuterContainerRider:{ display: 'flex', alignItems: 'center', alignContent: 'center', width: '100%' },
IconButtonStyleRider:{ minWidth: 'auto', padding: 0 },
marginRightRider:{ marginRight: '20px' },
NotificationTextStyleRider:{ color: '#0F172A', fontSize: '22px', fontWeight: 700 },
bigTextRider:{
  display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: 700, color: ' #0F172A'
},
ButtonStyleRider:{ backgroundColor: '#f0f5f9', height: 80, borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' },
secondButtonStyleRider:{
  backgroundColor: '#FFFFFF', height: 80, borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
},
thirdButtonSTyleRider:{ backgroundColor: '#f8fafc', height: 80, borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' },
ellipseStyleRider:{
  display: 'block', alignItems: 'center', marginLeft: '34px', paddingTop: '10px', color: '#334155', fontSize: '16px', fontWeight: 400, overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis', 
  maxWidth: '100%', 
  
},
smallImageStyleRider:{ width: '24px', height: '24px', marginRight: '10px', display: 'inline-flex',},
ListItemButtonStyleRider:{ pl: 4, backgroundColor: '#FFFFFF', height: 80 },
  };
  // Customizable Area End
