Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.Updateprofile = 'bx_block_profile/profiles/update_driver_profile'
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.editProfile = "Edit profile";
exports.name = "Cameron Rain";
exports.fname = "First Name";
exports.lname = "Last Name";
exports.email = "Email";
exports.address = "Home Address";
exports.phone = "Mobile Number";
exports.requirement = "Additional Requirements?";
exports.cant = "I can walk up stairs";
exports.delete = "Delete Account?";
exports.save = "Save";
exports.deleteAccount = "Delete Account";
exports.deleteSure = "Are you sure that you wish to delete your account?";
exports.cancel = "Cancel";
exports.deletetxt = "Delete";
exports.uploadPhoto = "Upload Photo";
exports.removePhoto = "Remove Photo";
exports.pswrd = "Password";
exports.none = "None";
exports.wheelchair = "Wheelchair";
exports.gurney = "Gurney";
exports.userProfileEndPoint = "bx_block_profile/profile/show";
exports.drivinglicence = "Driving licence number";
exports.insaurencetext = "Auto Insurance Policy number";
exports.dvmdocument = 'DMV document upload';
exports.insaurencedoucment = "Auto insurance policy document upload";
exports.drivinglicencedocs = "Driving licence Picture upload";
exports.vehicletext = 'Vehicle Type'
exports.vehiclemake = 'Vehicle Make';
exports.vehiclemodal = "Vehicle Model";
exports.year = "Vehicle Year";
exports.seatsavailable = "Number of Seats";
exports.vehicleplate = "Vehicle Licence Plate"
exports.enter = "Enter"
// Customizable Area End

