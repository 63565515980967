// Customizable Area Start
import React from "react";
import { Props } from "./HelpCentreController";
import HelpCentreFaqController, { configJSON } from "./HelpCentreFaqcontroller.web";
import { Box, Typography, styled, TextField } from "@mui/material"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { search, thinking } from "./assets"
// Customizable Area End

// Customizable Area Start
const ContactusInputGroup = styled(Box)({
    display: "flex",
    gap: "20px",
    marginTop: "40px",
    alignItems: "center"
});
const InputWrapper = styled(Box)({
    position: "relative",
    width: "70%",
    zIndex: 2,
    "@media (max-width: 900px)": {
        width: "100%"
    },
});
const Queanscontainer = styled(Box)({
    display: "flex",
    marginTop: "20px",
    justifyContent: "space-between",
    paddingBottom: "15px",
    paddingRight: "34px",
    "@media (max-width: 900px)": {
        flexDirection: "column",
        paddingRight: "10px",
    },
});
const Queansrightsidecontainer = styled(Box)({
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    "@media (max-width: 900px)": {
        width: "100%"
    },
});
const Queansleftsidecontainer = styled(Box)({
    width: "40%",
    "@media (max-width: 900px)": {
        width: "100%"
    },
});
const Knoweridetext = styled(Box)({
    fontFamily: "Inter",
    fontSize: "18.24px",
    fontWeight: 400,
    lineHeight: "27.35px",
    color: "#94A3B8",
    "@media (max-width: 900px)": {
        textAlign: "center"
    },
});
const Searchbutton = styled(Box)({
    width: "110px",
    height: "58.31px",
    background: "#1D6FB8",
    color: "white",
    textTransform: 'capitalize' as "capitalize",
    borderRadius: "12.16px",
    fontFamily: "Inter",
    fontSize: "21.27px",
    fontWeight: 400,
    lineHeight: "33.43px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "@media (max-width: 473px)": {
        fontSize: "15.27px",
        lineHeight: "20.43px",
    },
});
// Customizable Area End

export default class HelpCentreFaq extends HelpCentreFaqController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>
                <Queanscontainer style={{}}>
                    {this.state.showerror ? <> <Typography style={webstyle.faqtext}>No Search Found</Typography></> :
                        <Queansrightsidecontainer >
                            <Typography style={webstyle.faqtext}>FAQS</Typography>
                            {
                                this.state.helpCentreQA?.map((item: any, index: any) => (
                                    <div key={index} >
                                        <Accordion style={{ width: "100%", borderRadius: "16px", borderLeft: "2px solid #87C122", }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2-content"
                                                id="panel2-header"
                                                style={webstyle.quetext}
                                            >
                                                {index + 1}-{item.attributes.question}
                                            </AccordionSummary>
                                            <AccordionDetails style={webstyle.anstext}>
                                                * {item.attributes.answer}
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                ))
                            }

                        </Queansrightsidecontainer>}
                    <Queansleftsidecontainer >
                        <Box style={webstyle.imagethinking}><img src={thinking.default} /></Box>
                        <Typography style={webstyle.anyquetext}>{configJSON.anyquestionText}</Typography>
                        <Knoweridetext >{configJSON.askAnythingtext}</Knoweridetext>
                        <ContactusInputGroup>
                            <InputWrapper>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    style={{ borderRadius: "8px" }}
                                    placeholder="Search FAQs"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleSearch}
                                    onKeyDown={this.handleKeyDown}
                                    data-test-id="searchfaqhandlechange"
                                    name="name"
                                    InputProps={{
                                        sx: {
                                            borderRadius: '12.16px',
                                            background: "white",
                                            height: "66.86px",
                                            fontSize: '18.24px',
                                            fontFamily: "Inter",
                                            paddingLeft: "35px",
                                            fontWeight: 400,
                                            lineHeight: "27.35px",
                                            color: "#0F172A",
                                        },
                                    }}
                                />
                                <img src={search.default} style={webstyle.searchiconimage} />
                            </InputWrapper>
                            <Searchbutton onClick={this.searchqueAns} data-test-id="searchqueansid">{configJSON.searchText}</Searchbutton>
                        </ContactusInputGroup>
                    </Queansleftsidecontainer>
                </Queanscontainer>

            </Box>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webstyle = {
    quetext: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        marginLeft: "10px",
        color: "#000000"
    },

    searchiconimage: {
        position: "absolute" as "absolute",
        left: "12px",
        top: "18px",
        width: "34px",
        height: "34px"
    },

    faqtext: {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        color: "#334155"
    },

    imagethinking: {
        display: "flex",
        justifyContent: "center"
    },

    anstext: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#1D6FB8",
        marginLeft: "10px"
    },

    anyquetext: {
        fontFamily: "Inter",
        fontSize: "21px",
        fontWeight: 700,
        lineHeight: "33.43px",
        color: "#334155",
        textAlign: "center" as "center"
    },

    knoweridetext: {
        fontFamily: "Inter",
        fontSize: "18.24px",
        fontWeight: 400,
        lineHeight: "27.35px",
        color: "#94A3B8",
    }
}
// Customizable Area End
