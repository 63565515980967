import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  AppBar,
  Button,
  Typography,
  IconButton,
  Menu,
  Toolbar,
  MenuItem,
} from "@mui/material";
import { logo, profile } from "./assets";
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <AppBar position="static" style={{ background: "white", border: "1px solid #E2E8F0" }}>
        <Container maxWidth="xl" >
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              width={200}
              component="a"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'black',
                textDecoration: 'none',

              }}
            >
              <img src={logo.default} />

            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                style={{ color: "black" }}
                onClick={this.handleOpenNavMenu}
                data-test-id="handlenavmenu"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(this.state.anchoruser)}
                onClose={this.handleCloseUserMenu}
                data-test-id="closenavmenu"
                sx={{ display: { xs: 'block', md: 'none', marginTop: "39px", marginLeft: "13px" } }}
              >
                {this.state.pages.map((page: any, index: any) => (
                  <MenuItem key={page}
                    data-test-id="mobileredirection"
                    onClick={() => this.redirect(page, index)}>
                    <Typography
                      onClick={this.handleCloseUserMenu}
                      sx={{
                        textAlign: 'center',
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: 700,
                        lineHeight: "18px",
                        color: "#0F172A",
                        textTransform: "capitalize"
                      }}

                    >{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              width={200}
              noWrap
              component="a"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'black',
                textDecoration: 'none',
              }}
            >
              <img src={logo.default} style={{ width: "200px" }} />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} style={{ gap: '30px' }}>
              {this.state.pages.map((page: any, index: any) => (
                <Button
                  key={page}
                  style={index == this.state.activetab ? { background: "#87C122", width: "115px", height: "34px" } : {}}
                  onClick={() => this.redirect(page, index)}
                  data-test-id="activetabselect"
                  sx={{
                    my: 2,
                    textAlign: 'center',
                    fontSize: "12px",
                    fontWeight: 700,
                    lineHeight: "18px",
                    display: 'block',
                    textTransform: "capitalize",
                    color: "#0F172A",
                    borderRadius: "24px",
                    padding: '8px, 6px, 8px, 6px'
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="">
                <Avatar alt="Remy Sharp" src={profile.default} style={{ width: "24px", height: "24px", color: "#0F172A" }} onClick={this.redirectoMAinmenu} data-test-id="backtosidemenuid" />
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
    // Customizable Area End
  }
}

// Customizable Area Start


const webStyle = {
  userProfileWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },
  userProfile: {
    width: 100,
  },
  userDesignation: {
    fontSize: 14,
    color: "#ccc",
  },
  logout: {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  drawerItemIcon: {
    width: 20,
    selfAlign: "center",
    marginLeft: 7,
  },
};
// Customizable Area End
