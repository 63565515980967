import React from "react";

// Customizable Area Start

import {
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import { Box, Grid, TextField, IconButton, Typography,styled } from '@mui/material';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { Add,attachFile,mic,send, smileface ,back  } from "./assets";
import "../../email-account-registration/src/EmailAccountRegistratin.css"
import Picker from 'emoji-picker-react';
const theme = createTheme({
  typography: {
    fontFamily: '"Inter", "Roboto", "Arial", sans-serif',
  },
});
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import MicOffIcon from '@mui/icons-material/MicOff';
// Customizable Area End

import ChatRiderDriverController, {
  Props,
} from "./ChatRiderDriverController.web";

// Customizable Area Start
const SmallImage = styled('img')(({ theme }) => ({
  width: '24px',
  height: '24px',

  [theme.breakpoints.down('md')]: {
    width: '20px',
    height: '20px',
  },

  [theme.breakpoints.down('sm')]: {
    width: '16px',
    height: '16px',
  },
}));
const AttachFileImage = styled('img')(({ theme }) => ({
  width: '28px',
  height: '28px',

  [theme.breakpoints.down('md')]: {
    width: '24px',
    height: '24px',
  },

  [theme.breakpoints.down('sm')]: {
    width: '20px',
    height: '20px',
  },
}));
const ResponsiveContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column", // Stack items vertically on small screens
  },
}));

const RightBoxContainer = styled(Box)(({ theme }) => ({
  padding: "10px",
  paddingRight: "10%",
  display: "flex",
  justifyContent: "flex-end",
  [theme.breakpoints.down("sm")]: {
    // order: 2, // Make this appear below the left box on small screens
    paddingRight: 0, // Adjust padding for small screens,
    display: "flex",
  justifyContent: "center",
  },
}));
// Customizable Area End

export default class ChatRiderDriver extends ChatRiderDriverController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
 
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { messages} = this.state;
    return (
      <ThemeProvider theme={theme}>
        <NavigationMenu navigation={this.props.navigation} id={""} />

        <Grid container spacing={2} justifyContent="center" >
          <Grid item xs={6}>
            {this.state.showEmojiPicker && (
              <Box sx={{
                position: 'absolute',
                bottom: '20%',
                left: '20px',
                // Center the picker on smaller screens
                '@media (max-width: 600px)': {
                  left: '50%',
                  transform: 'translateX(-50%)',
                  bottom: '40%',
                },
              }}>
                <Picker onEmojiClick={this.onEmojiClick} />
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={webStyles.messageContainer1}
              >
                <div style={webStyles.chatBox}>
                  <img
                    src={back.default}
                    alt="Chat Icon"
                    style={webStyles.chatIcon}
                    onClick={this.goBack}
                  />
                  <Typography style={webStyles.chatText}>Chat</Typography>
                </div>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={webStyles.emptyBox}
            >
            </Box>
          </Grid>

        </Grid>
        <Grid container spacing={2} sx={{ width: '100%', overflowX: 'hidden' }}>
          <Grid item xs={12} sm={6} style={{ padding: '0px' }}>
            <Box
              sx={webStyles.messageOuter}
            >
              <Box
                sx={{ ...webStyles.messageInner, height: { xs: '50vh', sm: '72vh' }, }}
              >
                {messages.map((msg: any, index: any) => {
                  return (

                    <Box
                      key={index}
                      sx={{
                        ...webStyles.message, justifyContent:
                        this.getMessageAlignment(msg.user),
                      }}
                    >
                      <Box
                        sx={{
                          ...webStyles.messageBox, textAlign: msg.user === "User1" ? "right" : "left",
                        }}
                      >

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: msg.user === "User1" ? "flex-end" : "flex-start", // Align content
                            marginBottom: '20px'
                          }}
                        >
                          {msg.user != "User1" && (<>
                            <div style={{
                              ...webStyles.UserImageBox,

                            }}>
                              <img
                                src="https://randomuser.me/api/portraits/men/75.jpg"
                                alt="Profile"
                                style={webStyles.UserImage}
                              />
                            </div> <span style={{
                              ...webStyles.UserFirst,
                              wordWrap: 'break-word',
                              whiteSpace: 'pre-wrap', // Preserve line breaks and wrap text as needed
                              overflowWrap: 'break-word', // Break lines within words if necessary
                              overflow: 'hidden', // Hide any overflow beyond maxWidth
                              textOverflow: 'ellipsis',// Optionally, add an ellipsis for overflowed text
                            }}>
                              {
                                (msg.msgType === "text" || msg.msgType === "file")
                                  ? this.renderMessageWithStyledEmoji(msg.message)
                                  : msg.msgType === "audio"
                                    ? (
                                      <AudioContainer key={index}>
                                        <IconButton data-test-id="playPauseButton2" onClick={() => this.togglePlayPause(msg.url)} sx={{ p: 0.5 }}>
                                          {this.state.isPlaying && this.state.currentAudio === msg.url ? <PauseIcon /> : <PlayArrowIcon />}
                                        </IconButton>
                                        <ProgressContainer>
                                          <ProgressBar style={{ width: `${this.state.progress}%` }} />
                                        </ProgressContainer>
                                        <TimeLabel>
                                          {this.formatTime(this.state.currentTime)} / {this.formatTime(this.state.duration)}
                                        </TimeLabel>
                                      </AudioContainer>
                                    )
                                    : null
                              }
                            </span></>)}
                          <audio
                            ref={this.audioChunks}
                            onTimeUpdate={this.handleTimeUpdate}
                            onEnded={this.onEnd}
                            style={{ display: "none" }}
                          />

                          {msg.user === "User1" && (<> <span style={{
                            ...webStyles.UserSecond, wordWrap: 'break-word',
                            whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word',
                            overflow: 'hidden', // Hide any overflow beyond maxWidth
                            textOverflow: 'ellipsis',// Optionally, add an ellipsis for overflowed text
                            textAlign: 'left', // Ensure all text is left-aligned inside the bubble
                            display: 'inline-block' // Keep the bubble size only as wide as the text content
                          }}>
                            {
                              (msg.msgType === "text" || msg.msgType === "file")
                                ? this.renderMessageWithStyledEmoji(msg.message)
                                : msg.msgType === "audio"
                                  ? (
                                    <AudioContainer key={index}>
                                      <IconButton data-test-id="playPauseButton" onClick={() => this.togglePlayPause(msg.url)} sx={{ p: 0.5 }}>
                                        {this.state.isPlaying && this.state.currentAudioUrl === msg.url ? <PauseIcon /> : <PlayArrowIcon />}
                                      </IconButton>
                                      <ProgressContainer>
                                        <ProgressBar style={{ width: this.state.currentAudioUrl === msg.url ? `${this.state.progress}%` : "0%" }} />
                                      </ProgressContainer>
                                      <TimeLabel>
                                        {this.state.currentAudioUrl === msg.url
                                          ? `${this.formatTime(this.state.currentTime)} / ${this.formatTime(this.state.duration)}`
                                          : "0:00 / 0:00"}
                                      </TimeLabel>
                                    </AudioContainer>)
                                  : null
                            }
                            <audio
                              ref={this.audioChunks}
                              onTimeUpdate={this.handleTimeUpdate}
                              onEnded={this.onEnd}
                              style={{ display: "none" }}
                            />
                          </span><div style={webStyles.ImageBox2}>
                              <img
                                src="https://randomuser.me/api/portraits/men/75.jpg"
                                alt="Profile"
                                style={webStyles.UserImage}
                              />
                            </div></>)}

                        </div>    </Box>
                    </Box>
                  )
                })}
              </Box>

              <Box
                sx={webStyles.lowerBox}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton onClick={this.toggleEmojiPicker}>
                    <SmallImage src={smileface.default} alt="smileface" />
                  </IconButton>

                  <IconButton component="label">
                    <AttachFileImage src={attachFile.default} alt="smileface" />
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={this.handleFileChange}
                    />
                  </IconButton>

                  <IconButton onClick={this.toggleRecording}>
                    {this.state.isRecording ? (
                      <MicOffIcon sx={{ color: 'red' }} />
                    ) : (
                      <SmallImage src={mic.default} alt="mic" />
                    )}
                  </IconButton>
                </Box>

                <TextField
                  sx={{ flex: 1, mx: 2 }}
                  value={this.state.message}
                  onChange={this.handleChange}
                  placeholder="Message Matt"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                />

                <IconButton
                  sx={{
                    backgroundColor: "#ECFDF5",
                    color: "#fff",
                    borderRadius: "10px",
                    padding: "10px",
                    "&:hover": {
                      backgroundColor: "#D8E9E1",
                    },
                  }}
                  onClick={this.sendMessage}
                >
                  <SmallImage src={send.default} alt="smileface" />
                </IconButton>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <RightBoxContainer>
              <div
                style={{ ...webStyles.rightBox, flexDirection: 'column', }}
              >
                <Box
                  sx={{
                    width: '100%',
                    padding: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    backgroundColor: 'white'
                  }}
                >
                  <Typography style={{ fontSize: '22px', fontWeight: 700 }}>Chat</Typography>
                  <IconButton
                    sx={{
                      backgroundColor: "#ECFDF5",
                      color: "#fff",
                      borderRadius: "10px",
                      padding: "10px",
                      "&:hover": {
                        backgroundColor: "#D8E9E1",
                      },
                    }}
                    onClick={this.sendMessage}
                  >
                    <SmallImage src={Add.default} alt="smileface" />
                  </IconButton>
                </Box>
                <Box
                  sx={webStyles.middleBox}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src="https://randomuser.me/api/portraits/men/75.jpg"
                      alt="Profile"
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        marginRight: '10px',
                      }}
                    />

                    <Box>
                      <div style={webStyles.userNameDiv}>
                        John Doe
                        <span
                          style={{
                            width: '10px',
                            height: '10px',
                            backgroundColor: 'green',
                            borderRadius: '50%',
                            display: 'inline-block',
                            marginLeft: '5px',
                          }}
                        ></span>
                      </div>
                      <Typography style={webStyles.welcomeText}>You are welcome</Typography>
                    </Box>
                  </Box>


                  <Typography style={webStyles.hoursText}>3h</Typography>
                </Box>
              </div>
            </RightBoxContainer>
          </Grid>


        </Grid>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const AudioContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  padding: "8px 12px",
  borderRadius: "8px",
  backgroundColor: theme.palette.background.default,
  maxWidth: "300px",
  margin: "8px 0",
  boxShadow: theme.shadows[1],
}));

const ProgressContainer = styled("div")(({ theme }) => ({
  flexGrow: 1,
  height: "4px",
  backgroundColor: theme.palette.grey[300],
  borderRadius: "4px",
  position: "relative",
  overflow: "hidden",
}));

const ProgressBar = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  backgroundColor: theme.palette.primary.main,
  transition: "width 0.1s linear",
}));

const TimeLabel = styled("span")(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.text.secondary,
}));
const webStyles = {
  audioContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    backgroundColor: "#f0f0f0",
    padding: "8px 12px",
    borderRadius: "8px",
    maxWidth: "300px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
  },
  playPauseButton: {
    padding: "8px"
  },
  progressContainer: {
    position: "relative",
    flexGrow: 1,
    height: "4px",
    backgroundColor: "#ddd",
    borderRadius: "4px",
    overflow: "hidden"
  },
  progressBar: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    backgroundColor: "#4caf50",
    transition: "width 0.1s linear"
  },
  timeLabel: {
    fontSize: "12px",
    color: "#555"
  },
  welcomeText:{ color: '#0F172A', fontSize: '16px' },
  hoursText:{ fontWeight: 400, color: '#64748B', fontSize: '14px' },
  userNameDiv:{ fontWeight: 700, display: 'flex', alignItems: 'center', paddingBottom: '5px', color: '#87C122', fontSize: '16px' },
  middleBox:{
    width: '100%',
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'space-between', 
    padding: '10px',
    paddingRight: '20px',
    backgroundColor: '#ECFDF5',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px'
  },
  rightBox:{
    width: '60%', 
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', 
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'
  },
  lowerBox:{
    display: "flex",
    alignItems: "center",
    padding: "5px 10px",
    borderRadius: "20px",
    backgroundColor: "white",
    width: "70%", 
    marginTop: "10px", 
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'
  },
  ImageBox2:{ width: '60px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  UserSecond:{
    padding: "12px", backgroundColor: 'white',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    width: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
    font: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    color: '#64748B',
    maxWidth: '95%',
  },
  UserFirst:{
    padding: "12px", width: '100%', borderRadius: '10px', backgroundColor: 'white',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
    font: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    color: '#64748B'
  },
  UserImage:{
    width: '30px', 
    height: '30px',
    borderRadius: '50%',
    marginRight: '10px', 
  },
  UserImageBox:{ width: '60px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  messageBox:{
    display: "inline-block",
    width: '100%', 
    borderRadius: "10px",
  },
  message:{
    display: "flex",
  
    width: "100%", 
    marginBottom: "10px",
  },
  messageInner:{
    height: "72vh",
    borderRadius: "10px",
    padding: "10px",
    overflowY: "scroll",
    width: "70%", 
    scrollbarWidth: "none",
    msOverflowStyle: "none", 
    '&::-webkit-scrollbar': { 
      display: "none"
    }
  },
  messageOuter:{
    display: "flex",
    flexDirection: "column",
    alignItems: "center", 
    width: "100%",
    padding: '0px'
  },
  emptyBox:{
    display: 'flex',
    justifyContent: 'flex-end',
  },
  chatText:{ fontSize: '22px', fontWeight: 700,marginLeft: '8px'  },
  chatBox:{ width: '100%', paddingTop: '2%', paddingBottom: '3%',
      display: 'flex', 
    alignItems: 'center' 
    },
  messageContainer1:{
    width: "70%",
    paddingTop: '20px',
    display: 'flex', 
    alignItems: 'center',
    scrollbarWidth: "none",
    msOverflowStyle: "none", 
    '&::-webkit-scrollbar': { 
      display: "none"
    }
  },
  chatIcon: {
    width: '24px', // Set width of the image
    height: '24px', // Set height of the image
  },
  OuterBoxStyle:{
    display: "flex",
    flexDirection: "column",
    alignItems: "center", 
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    width: 600,
    border: "1px solid #ccc",
    justifyContent: "space-between",
    padding: 20,
    alignItems: "center",
  },
  headerButtonView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 10,
    
  },
  messageContainer: {
    display: "flex",
    flexDirection: "column-reverse",
    width: 600,
    height: 600,
    overflow: "scroll",
  },
  messageBoxContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#d9d6ed",
    borderRadius: 30,
    borderWidth: 1,
    borderColor: "#ccc",
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
    marginRight: 40,
  },
  messageInputView: {
    width: "100%",
    marginRight: 10,
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 20,
    width: 600,
    border: "1px solid #ccc",
    background: "#fff",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "row",
    position: "absolute" as "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  previewModalContainer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute" as "absolute",
    width: '80%',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  previewImage: {
    width: "100%",
    height: "auto",
  },
  previewInput: {
    marginLeft: 10,
  },
  modalButtonView: {
    marginLeft: 10,
  },
  previewModalButtons: {
    margin: 10,
    display: "flex",
    flexDirection: "row",
  },
  unreadMessage: {
    color: "red",
  },
  readMessage: {
    color: "black",
  },
  fileInput: {
    display: "none",
  },
};
// Customizable Area End
