import React from "react";

// Customizable Area Start

import PaymentScreenController, {
  Props
} from "./PaymentScreenController.web";
import {
  Box,
  styled,
  Button,
  TextField,
  FormLabel,
  FormControl,
  Snackbar,
} from '@mui/material';
import { shopImage,} from "./assets";
import MuiAlert from '@mui/material/Alert';
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config");
import "../../email-account-registration/src/EmailAccountRegistratin.css"
// Customizable Area End

export default class PaymentScreen extends PaymentScreenController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End
  render() {
    // Customizable Area Start
    const { errors } = this.state;
    return (
      <>
     <div>
        <MainContainer>
        
          <FormContainer onSubmit={this.handleSubmit} data-testID={"submitID"}>
              <div style={{ display: 'flex', alignItems: 'center',marginBottom:'20px' }}>
                <img
                  src={shopImage.default}
                  alt="shop"
                  style={{ marginRight: '20px', }}
                />
                <span  className="payment-text-style">{configJSON.bankText}</span>
              </div>
              <div >Profile Updated sucessfully</div> 
            <FormControl fullWidth >
              <MyFormLabel>{configJSON.AcName}</MyFormLabel>
              <Input fullWidth variant="outlined"
                data-testID={"inputID"}
                name={"AcHolderName"}
                value={this.state.AcHolderName}
                onChange={this.handleChangeInput}
                placeholder="Account Holder Name"
                inputProps={{
                  minLength: 3,
                  maxLength: 50,
                }}
               
               
              />
              {errors.AcHolderName && <div className="Payment-error" style={{marginBottom:'20px'}}>{errors.AcHolderName}</div>}

            </FormControl>

             <FormControl fullWidth >
              <MyFormLabel>{configJSON.RNumber}</MyFormLabel>
              <Input fullWidth variant="outlined" name={"RNumber"}
                value={this.state.RNumber}
                onChange={this.handleChangeInput}
                placeholder={`${configJSON.RNumber}`}   
                inputProps={{
                  minLength: 3,
                  maxLength: 50,
                }}
               
                
              />
              {errors.RNumber && <span className="Payment-error" style={{marginBottom:'20px'}}>{errors.RNumber}</span>}

            </FormControl>

            <FormControl fullWidth >
              <MyFormLabel>{configJSON.ANumber}</MyFormLabel>
              <Input fullWidth variant="outlined" name={"AcNumber"}
                value={this.state.AcNumber}
                onChange={this.handleChangeInput}
                placeholder={`${configJSON.ANumber}`}
               
              />
               {errors.AcNumber && <span className="Payment-error" style={{marginBottom:'20px'}}>{errors.AcNumber}</span>}
            </FormControl>
              <div className="payment-text-container">
                <p className="payment-text-style1">{configJSON.DebitText}</p>
                <p className="payment-text-style2">{configJSON.longText}</p>
              </div>
            <Box style={webstyle.drivereditbuttonboxcontainer}>
              <Drivereditbuttonbasic>
                <SaveButton type={"submit"}>
                  <Button style={webstyle.savetextcolor} >Save</Button>
                </SaveButton>
               <CancelButton>
              Cancel

               </CancelButton>
              </Drivereditbuttonbasic>
            </Box>
          </FormContainer>
          <Snackbar
          open={this.state.isToastOpen}
          autoHideDuration={2000}
          onClose={this.handleToastClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}  // Position: top-right
        >
          <MuiAlert onClose={this.handleToastClose} severity="success"
            sx={{ backgroundColor: '#4caf50', color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
            Bank details updated sucessfully
          </MuiAlert>
        </Snackbar>
        </MainContainer>
      </div>
    </>
    );
    // Customizable Area End
  }


}


  // Customizable Area Start
  const MainContainer = styled(Box)({
    minHeight: "100vh",
    backgroundColor: "#ECFDF5",
    "@media (max-width: 960px)": {
      minHeight: "auto",
    },
  });
  
  const Drivereditbuttonbasic = styled(Box)({
    width: "70%",
    display: "flex",
    flexDirection: "column" as "column",
    gap: '10px',
    alignItems: "center",
    "@media (max-width: 960px)": {
      width: "100%",
    },
  });
  
  const FormContainer = styled("form")({
    "& .css-1hf16px-MuiFormControl-root-MuiTextField-root": {
      border: "none"
    },
    width: "95%",
    padding: "10px",
    "@media (max-width: 960px)": {
      width: "100%",
    }
  });
  
  const Input = styled(TextField)({
    backgroundColor: '#ffffff',
    marginBottom: '16px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      backgroundColor: "#ffffff",
    },
  });
  
  const MyFormLabel = styled(FormLabel)({
    color: '#334155',
    fontWeight: 700,
    fontSize: "14px",
    marginBottom: "5px",
    lineHeight: "22px"
  });
  
 
  
  const CancelButton = styled(Button)({
    background: "#FFFFFF",
    justifyContent: "center",
    width: "50%",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    border:'1px solid #1D6FB8',
    height: "56px",
    color: "#1D6FB8",
    textTransform: 'none',
    fontFamily: "Inter",
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "28px",
    "@media (max-width: 600px)": {
      width: "90%",
    },
    '&:hover': {
      backgroundColor:'#f0f0f0',
    },
  });
  
  
  
  
  
  const SaveButton = styled(Button)({
    background: "#1D6FB8",
    justifyContent: "center",
    width: "50%",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    height: "56px",
    color: "black",
    "@media (max-width: 600px)": {
      width: "90%",
    },
    '&:hover': {
      backgroundColor: "#1D6FB8",
    },
  });

  const webstyle = {
    drivereditbuttonboxcontainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      paddingBottom: "15px",
      paddingTop: "15px"
    },
  
    savetextcolor: {
      color: "#ECFDF5",
      fontFamily: "Inter",
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "28px",
      textTransform: "capitalize" as "capitalize"
    },
  
    nexttextcolor: {
      color: "#1D6FB8",
      textTransform: "capitalize" as "capitalize",
      fontFamily: "Inter",
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "28px",
    },
  
  }
  // Customizable Area End
