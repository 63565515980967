Object.defineProperty(exports, "__esModule", {
    value: true,
  });
  
  // Customizable Area Start
  exports.apiContentType = "application/json";
  exports.confirmPaymentMethod = "POST";
  exports.stripePaymentIntent = "stripe_integration/payment_intents"
  
  exports.stripePublishableKey = ""
  exports.stripeMerchantDisplayName = "Example.com"
  exports.stripeMerchantIdentifier = "stripeMerchantIdentifier" //used for apple pay
  exports.urlScheme = "rnbuildingblockmasterapp" //app deep linking url AcName
  exports.AcName = "Account holder name";
  exports.RNumber = "Routing number";
  exports.ANumber = "Account number";
  exports.DebitText = "Direct Debit authorization";
  exports.longText = "By adding your bank account to your E-Ride account and clicking below, you authorize E-Ride to debit your bank as described in these terms.";
  exports.bankText = "We'll send your USD payouts to this bank account.";
  // Customizable Area End
  