import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  selectedSection: string;
  setSelectedSection: (section: string) => void;
   // Customizable Area End
}

export interface S {
  // Customizable Area Start
  openFirst:boolean;
  openSecond:boolean;
  openThird:boolean;
  openFourth:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class NotificationUIDriverController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      openFirst:false,
      openSecond:false,
      openThird:false,
      openFourth:false,

    };    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleBackToMenu = () => {
    // Set the selectedSection back to the initial component
    this.props.setSelectedSection("appointment"); // Update this to your initial section
  };
  handleFirstClick = () => {
    this.setState((prevState) => ({
      openFirst: !prevState.openFirst,
    }));
  };

  handleSecondClick = () => {
    this.setState((prevState) => ({
      openSecond: !prevState.openSecond,
    }));
  };
  handleThirdClick = () => {
    this.setState((prevState) => ({
      openThird: !prevState.openThird,
    }));
  };

  handleFourthClick = () => {
    this.setState((prevState) => ({
      openFourth: !prevState.openFourth,
    }));
  };
  // Customizable Area End
}