import React from "react";

// Customizable Area Start
import { Box, styled, Typography, IconButton, List,Collapse, ListItemButton, ListItemText ,Switch,FormControlLabel,FormGroup ,SwitchProps } from "@mui/material";
import { rightSideImage,back_Icon,notification,star,swap,update} from "./assets";
import NotificationUIDriverController, {
  Props
} from "./NotificationUIDriverController.web";
import "../../email-account-registration/src/EmailAccountRegistratin.css";
import {  ExpandMore,NavigateNext} from '@mui/icons-material';
const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start


const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "20px",
  width: "100vw", 
  height: "calc(100vh - 80px)",  
  boxSizing: "border-box",
  position: "relative",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      height: "auto", 
      width: "100%",  
      padding: "0px",
  },
}));

const LeftColumn = styled(Box)(({ theme }) => ({
  flex: "0 0 55%",  
  padding: "10px",
  overflowY: "auto",
  scrollbarWidth: "none", 
  msOverflowStyle: "none", 
  '&::-webkit-scrollbar': { 
    display: "none"
  },
  [theme.breakpoints.down("md")]: {
      width: "100%",
      flex: "none",
      padding:'0px'
  },
}));

const RightColumn = styled(Box)(({ theme }) => ({
  flex: "0 0 45%",  
  padding: "10px",
  position: "relative",
  display:'flex',
  alignItems:'center',
  [theme.breakpoints.down("md")]: {
      width: "100%",
      flex: "none",
      marginTop: "20px",
      height:'500px'
      
  },
}));

const FixedImageContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  paddingLeft:'20%',
  top: "30%",  
  width: "80%",
  height: "70%",  
  overflow: "hidden",
// backgroundColor:'red',
  [theme.breakpoints.down("md")]: {
    top: "0%",       
    height: "100%",
  },
}));

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  marginLeft: '45px',
  marginTop: '25px',
  fontSize: '18px',
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0px',   
    marginTop: '15px',   
    fontSize: '16px',    
  },
}));
const ResponsiveList = styled(List)(({ theme }) => ({
  marginLeft: '45px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0px', 
  },
}));
const ResponsiveCheckBox= styled(Box)(({ theme }) => ({
  marginLeft: '45px', 
  marginTop:'20px',
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,   
  },
}));
const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#1D6FB8', // Custom checked color
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
// Customizable Area End

export default class NotificationUIDriver extends NotificationUIDriverController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End
  render() {
    // Customizable Area Start
   
    return (

      <Container>
        <LeftColumn>
          <div style={webStyle.OuterContainer}>

            <IconButton style={webStyle.IconButtonStyle} onClick={this.handleBackToMenu} data-test-id="backButton">
              <img
                src={back_Icon.default} // Replace with your image URL
                alt="Back"
                style={webStyle.marginRight} // Add spacing between icon and text
              /> {/* Back Button */}
            </IconButton>

            <p style={webStyle.NotificationTextStyle}>
            {configJSON.notificationsTitle}
            </p>
          </div>
          <div>
            <ResponsiveTypography>
            {configJSON.todayLabel}
            </ResponsiveTypography>

            <ResponsiveList  >
              <ListItemButton
                onClick={this.handleFirstClick}
                sx={webStyle.ButtonStyle} data-test-id="FirstButton">


                <ListItemText
                  primary={
                    <span style={webStyle.bigText}>
                      <img
                        src={swap.default}
                        alt="Icon"
                        style={webStyle.smallImageStyle}
                      />
                      {configJSON.newRideAvailable}
                    </span>
                  }
                  secondary={
                    <span style={webStyle.ellipseStyle}>
                      {configJSON.newRideDetails}
                    </span>
                  }
                />
                {this.state.openFirst ? <ExpandMore /> : <NavigateNext />}
              </ListItemButton>

              <Collapse in={this.state.openFirst} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton sx={webStyle.ListItemButtonStyle}>

                    <ListItemText primary="Item1" />
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton
                onClick={this.handleSecondClick}
                sx={webStyle.secondButtonStyle} data-test-id="SecondButton">

                <ListItemText
                  primary={
                    <span style={webStyle.bigText}>
                      <img
                        src={notification.default}
                        alt="Icon"
                        style={webStyle.smallImageStyle} />
                      Reminder
                    </span>
                  }
                  secondary={
                    <span style={webStyle.ellipseStyle}>
                      Update your app for the latest features.
                    </span>
                  }
                />

                {this.state.openSecond ? <ExpandMore /> : <NavigateNext />}
              </ListItemButton>

              <Collapse in={this.state.openSecond} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton sx={webStyle.ListItemButtonStyle}>

                    <ListItemText primary="Item 2" />
                  </ListItemButton>
                </List>
              </Collapse>
            </ResponsiveList>
          </div>
          <div>
            <ResponsiveTypography>
            {configJSON.earlierLabel}
            </ResponsiveTypography>

            <ResponsiveList  >
              <ListItemButton
                onClick={this.handleThirdClick}
                sx={webStyle.thirdButtonSTyle} data-test-id="ThirdButton">


                <ListItemText
                  primary={
                    <span style={webStyle.bigText}>
                      <img
                        src={update.default}
                        alt="Icon"
                        style={webStyle.smallImageStyle} />
                     {configJSON.updateLabel}
                    </span>
                  }
                  secondary={
                    <span style={webStyle.ellipseStyle}>
                     {configJSON.updateProfilePicture}
                    </span>
                  }
                />
                {this.state.openFirst ? <ExpandMore /> : <NavigateNext />}
              </ListItemButton>

              <Collapse in={this.state.openThird} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton sx={webStyle.ListItemButtonStyle}>

                    <ListItemText primary="Item1" />
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton
                onClick={this.handleFourthClick}
                sx={webStyle.secondButtonStyle} data-test-id="FourthButton">

                <ListItemText
                  primary={
                    <span style={webStyle.bigText}>
                      <img
                        src={star.default}
                        alt="Icon"
                        style={webStyle.smallImageStyle}
                      />
                      {configJSON.rateLastTrip}
                    </span>
                  }
                  secondary={
                    <span style={webStyle.ellipseStyle}>
                      {configJSON.feedbackPrompt}
                    </span>
                  }
                />

                {this.state.openSecond ? <ExpandMore /> : <NavigateNext />}
              </ListItemButton>

              <Collapse in={this.state.openFourth} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton sx={webStyle.ListItemButtonStyle}>

                    <ListItemText primary="Item2" />
                  </ListItemButton>
                </List>
              </Collapse>
            </ResponsiveList>
          </div>
          <ResponsiveCheckBox >
            <FormGroup>

              <FormControlLabel
                control={<IOSSwitch defaultChecked />}
                label={configJSON.enableEmailNotifications}
                sx={{
                  margin: 0, // Remove any default margin
                  alignItems: 'center', // Center align switch and label
                  '& .MuiFormControlLabel-label': {
                    fontSize: '16px',
                    fontWeight: 500,
                    color: '#0F172A', // Label color
                    marginLeft: '8px', // Optional spacing between label and switch
                  },
                }}
              />
            </FormGroup>
          </ResponsiveCheckBox>
        </LeftColumn>

        <RightColumn>


          <FixedImageContainer>
            <img
              src={rightSideImage.default} // Replace with your image URL
              alt="Fixed"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </FixedImageContainer>
        </RightColumn>
      </Container>
 
    );
    // Customizable Area End
  }


}


  // Customizable Area Start
  const webStyle = {
 OuterContainer:{ display: 'flex', alignItems: 'center', alignContent: 'center', width: '100%', },
IconButtonStyle:{ minWidth: 'auto', padding: 0 },
marginRight:{ marginRight: '20px' },
NotificationTextStyle:{ color: '#0F172A', fontSize: '22px', fontWeight: 700 },
bigText:{
  display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: 700, color: ' #0F172A'
},
ButtonStyle:{ backgroundColor: '#f0f5f9', height: 80, borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' },
secondButtonStyle:{
  backgroundColor: '#FFFFFF', height: 80, borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
},
thirdButtonSTyle:{ backgroundColor: '#f8fafc', height: 80, borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' },
ellipseStyle:{
  display: 'block', alignItems: 'center', marginLeft: '34px', paddingTop: '10px', color: '#334155', fontSize: '16px', fontWeight: 400, overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis', 
  maxWidth: '100%', 
  
},
smallImageStyle:{ width: '24px', height: '24px', marginRight: '10px', display: 'inline-flex',},
ListItemButtonStyle:{ pl: 4, backgroundColor: '#FFFFFF', height: 80 },
  };
  // Customizable Area End
