export const choseedate = require('../assets/choosedate.png');
export const appointment = require('../assets/appointment.png');
export const map = require('../assets/map.png');
export const editicon = require("../assets/icon.png")
export const edit = require("../assets/edit.png")
export const deleteicon = require("../assets/delete.png")
export const date = require("../assets/date.png")
export const location = require("../assets/location.png")
export const time = require("../assets/time.png")
export const droplocation = require("../assets/droplocation.png")
