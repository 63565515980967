
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    helpCentreFaq: any;
    expandedField3:boolean;
    expandedField4:boolean;
    expandedField5:boolean;
    expandedField6:boolean;
    isStartDestOpen:boolean;
    home:any;
    work:any;
    location:any;
    selectedCar:string;
    isCarPopUpOpen:boolean;
    SucessModal:boolean;
    LocationErrrorPopUp:boolean;
    InternetErrorPopUp:boolean;
    // Customizable Area End
}

interface SS {
    id: any;
}
export default class PlanYourRideController extends BlockComponent<Props, S, SS> {
    getHelpCentreApiCallId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.handleField5 = this.handleField5.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            helpCentreFaq: [],
            expandedField3:false,
            expandedField4:false,
            expandedField5:false,
            expandedField6:false,
            isStartDestOpen:false,
            home:[ { name: 'Place 1', description: 'Description of Place 1' },
              ],
            work:[ { name: 'Place 1', description: 'Description of Place 1' },
                ],
            location:[ { name: 'Place 1', description: 'Description of Place 1' },
                { name: 'Place 2', description: 'Description of Place 2' },
               ],
               isCarPopUpOpen:false,
               selectedCar:'',
               SucessModal:false,
               LocationErrrorPopUp:false,
               InternetErrorPopUp:false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recieved", JSON.stringify(message));

        // Customizable Area End
    }

    // Customizable Area Start
    handleField3=()=>{
        this.setState({ expandedField3: !this.state.expandedField3 });
    }
    
    handleField4=()=>{
        this.setState({ expandedField4: !this.state.expandedField4 });
    }
    
    handleField5() {
        this.setState({ expandedField5: !this.state.expandedField5 });
    }
    handleField6() {
        this.setState({ expandedField6: !this.state.expandedField6 });
    }
    onStartDestClose=()=>{
        this.setState({ isStartDestOpen: false });
    }
    openStartDestPopup=()=>{
        this.setState({ isStartDestOpen: true });

    }
    onCarPopupClose=()=>{
        this.setState({isCarPopUpOpen:false})
    }
    onCarPopupOpen=()=>{
        this.setState({isCarPopUpOpen:true})
    }
    handleCarSelect = (car:any) => {
      this.setState({selectedCar:car})
      };
    handleSucessClose=()=>{
        this.setState({SucessModal:false})
    }
    handleSucessOpen=()=>{
        this.setState({SucessModal:true})
    }
    getBackgroundColor = (car: any, selectedCar: any) => {
        return selectedCar === car ? '#F1F5F9' : 'transparent';
    };
    getBorder = (car: any, selectedCar: any) => {
        return selectedCar === car ? '2.48px solid #E2E8F0' : '0px';
    }
    getBorderRadius = (car: any, selectedCar: any) => {
        return selectedCar === car ? '10px' : '0px';
    }
    
    openLocationErrorPopUp=()=>{
        this.setState({LocationErrrorPopUp:true})
    }
    openInternetErrorPopUp=()=>{
        this.setState({InternetErrorPopUp:true})
    }
    closeLocationErrorPopUp=()=>{
        this.setState({LocationErrrorPopUp:false})
    }
    closeInternetErrorPopUp=()=>{
        this.setState({InternetErrorPopUp:false})
    }
    // Customizable Area End
}