// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

import { Message } from "../../../framework/src/Message";
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
export interface ApiCallFunction {
    contentType: string,
    method: string,
    endPoint: string,
    body?: {}
}

export interface ValidResponse {
    data: {
        id: string,
        type: string,
        attributes: {
            email: string;
            first_name: string;
            last_name: string;
            role_id: number;
            status: string
        }
    },
    meta: {
        token: string;
    }
}

export interface InValidResponse {
    errors: [
        {
            detail: string
        }
    ]
}

export interface InValidUpdateResponse {
    errors: [string]
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}
export interface S {
    // Customizable Area Start
    role: string;
    firstName: string;
    lastName: string;
    email: string;
    emailError: boolean;
    password: string;
    agreeToTerms: boolean;
    openErrorModal: boolean;
    currentStep: number;
    openSelectModal: boolean;
    anythingElse: string;
    passwordCriteria: {
        hasUpperCase: boolean,
        hasLowerCase: boolean,
        hasNumber: boolean,
        hasSpecialChar: boolean,
        isValidLength: boolean
    };
    showPassword: boolean;
    mobileNumber: string;
    homeAddress: string;
    homeAddressError: boolean;
    addressOne: string;
    addressOneError: boolean;
    addressTwo: string;
    addressTwoError: boolean;
    city: string;
    cityError: boolean;
    stateAdd: string;
    stateAddError: boolean;
    country: string;
    countryError: boolean;
    zipCode: string;
    zipCodeError: boolean;
    showApiError: boolean;
    apiErrorMessage: string;
    btnLoader: boolean;
    requirement: string;
    allinputdata: object
    // Customizable Area End
}
export interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}
export default class SignUpDriverController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    createAccountForRiderApiCallId: string = "";
    updateRiderAccountApiCallId: string = "";
    getGoogleMapApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        // Customizable Area End

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            role: "rider",
            firstName: "",
            lastName: "",
            email: "",
            emailError: false,
            password: "",
            currentStep: 1,
            openSelectModal: false,
            openErrorModal: false,
            anythingElse: '',
            agreeToTerms: false,
            showPassword: false,
            passwordCriteria: {
                hasUpperCase: false,
                hasLowerCase: false,
                hasNumber: false,
                hasSpecialChar: false,
                isValidLength: false
            },
            mobileNumber: "",
            homeAddress: "",
            homeAddressError: false,
            addressOne: "",
            addressOneError: false,
            addressTwo: "",
            addressTwoError: false,
            city: "",
            cityError: false,
            stateAdd: "",
            stateAddError: false,
            country: "",
            countryError: false,
            zipCode: "",
            zipCodeError: false,
            showApiError: false,
            apiErrorMessage: "",
            btnLoader: false,
            requirement: "",
            allinputdata: {}
            // Customizable Area End
        };
    }

    // Customizable Area Start
    async componentDidMount() {
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const isTermsCondsAccepted = message.getData(
                getName(MessageEnum.TermAndConditionCheck)
            );
            const Prefilledinputdata = message.getData(
                getName(MessageEnum.Sendingbackallinputsdata)
            );
            this.setState({
                agreeToTerms: isTermsCondsAccepted, firstName: Prefilledinputdata.first_name, lastName: Prefilledinputdata.last_name,
                email: Prefilledinputdata.email, password: Prefilledinputdata.password, role: Prefilledinputdata.role_id,
                emailError: Prefilledinputdata.emailError, passwordCriteria: Prefilledinputdata.passwordCriteria
            })

        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId1 = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson1 = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId1 && responseJson1) {
                this.callBackResponse(apiRequestCallId1, responseJson1);
            }
        }
        // Customizable Area End
    }

    apiCallFunction = async (data: ApiCallFunction) => {
        const { contentType, method, endPoint, body } = data;
        let token = await getStorageData("tempToken");

        const header = {
            'Content-Type': contentType,
            token,
        }

        const requestMessage1 = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        requestMessage1.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage1.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        )
        requestMessage1.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        )
        body &&
            requestMessage1.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )

        runEngine.sendMessage(requestMessage1.id, requestMessage1);
        return requestMessage1.messageId;
    };

    callBackResponse = (apiRequestCallID: string, responseJSON: ValidResponse & InValidResponse & InValidUpdateResponse) => {
        if (this.isValidResponse(responseJSON)) {
            this.apiSuccessCallBacks(apiRequestCallID, responseJSON)
        };

        if (this.inValidResponse(responseJSON)) {
            this.apiErrorCallBacks(apiRequestCallID, responseJSON)
        };
    };

    isValidResponse = (responseJson: ValidResponse) => {
        return responseJson && responseJson.data;
    };

    inValidResponse = (errorReponse: InValidResponse) => {
        return errorReponse && errorReponse.errors;
    };

    apiSuccessCallBacks = (apiRequestCallID: string, responseJSON: ValidResponse) => {
        if (apiRequestCallID == this.createAccountForRiderApiCallId) {
            this.handleSuccessCreateAccount(responseJSON.data.id, responseJSON.meta.token)
        } else if (apiRequestCallID == this.updateRiderAccountApiCallId) {
            this.handleSuccessUpdateAccount();
        } else if (apiRequestCallID == this.getGoogleMapApiCallId) {
            console.log(responseJSON, "===@@@");

        }
    };

    apiErrorCallBacks = (apiRequestCallID: string, responseJSON: InValidResponse & InValidUpdateResponse) => {
        if (apiRequestCallID == this.createAccountForRiderApiCallId) {
            this.handleFailureCreateAccount(responseJSON.errors[0].detail)
        } else if (apiRequestCallID == this.updateRiderAccountApiCallId) {
            this.handleFailureUpdateAccount(responseJSON.errors[0])
        }
    };

    handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "SignUpDriver");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };

    handleChangeForFLName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;

        const sanitizedValue = value.replace(/[^a-zA-Z]/g, '');

        this.setState(prevState => ({
            ...prevState,
            [name]: sanitizedValue,
        }));
    }

    handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.currentTarget;
        const { name, value } = target;

        this.setState(prevState => ({
            ...prevState,
            [name]: value,
            [`${name}Error`]: false
        }));
    };

    handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value;
        const emailError = !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email);
        this.setState({
            email,
            emailError,
        });
    };

    handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const password = event.target.value;

        let passwordCriteria = {
            hasUpperCase: /[A-Z]/.test(password),
            hasLowerCase: /[a-z]/.test(password),
            hasNumber: /\d/.test(password),
            hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
            isValidLength: password.length >= 8,
        };

        this.setState({
            password,
            passwordCriteria,
        });
    };

    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword,
        });
    };

    navigateTermscondition = (route: string) => {
        localStorage.removeItem("redirectfrom")
        setStorageData("role", "rider");
        const navigate: Message = new Message(getName(MessageEnum.NavigationTermsandContion));
        navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        navigate.addData(getName(MessageEnum.NavigationcheckFromtermcondtion), true);
        navigate.addData(getName(MessageEnum.AllInputsdataforsignup), {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            password: this.state.password,
            emailError: this.state.emailError,
            passwordCriteria: this.state.passwordCriteria,
            role_id: this.state.role
        });


        this.send(navigate);
    }

    handleCheckboxChange = () => {
        this.setState({
            agreeToTerms: !this.state.agreeToTerms,
        });
    };

    handleSelectRequirement = (requirement: string) => {
        this.setState({ requirement, openSelectModal: false })
    }

    handleSubmitRiderAccount = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ btnLoader: true });
        let body = {
            data: {
                type: "email_account",
                attributes: {
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    email: this.state.email,
                    password: this.state.password,
                    role_id: "1"
                }
            }
        }

        this.createAccountForRiderApiCallId = await this.apiCallFunction({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.Post,
            endPoint: configJSON.postAPIDriver,
            body: body
        });

    };

    handleSuccessCreateAccount = async (id: string, token: string) => {
        setStorageData("tempID", id);
        setStorageData("tempToken", token);
        this.setState((prevState) => ({
            currentStep: prevState.currentStep + 1,
            btnLoader: false,
            showApiError: false,
            apiErrorMessage: "",
        }));
    };

    handleFailureCreateAccount = (message: string) => {
        this.setState({ showApiError: true, apiErrorMessage: message, btnLoader: false });
    };

    handleSubmitTwo = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (this.state.requirement == "") {
            this.setState({ showApiError: true, apiErrorMessage: configJSON.additionalRequirement });
        } else {
            this.setState((prevState) => ({
                currentStep: prevState.currentStep + 1,
                btnLoader: false,
                showApiError: false,
                apiErrorMessage: ""
            }));
        }
    };

    takeonlyNumber = (event: any) => {
        const input = event.target as HTMLInputElement;
        input.value = input?.value?.replace(/\D/g, "");
    }

    goToBackStep = () => {
        this.setState((prevState) => ({
            currentStep: prevState.currentStep - 1,
        }));
    };

    handleOpenSelectModal = () => {
        this.setState({ openSelectModal: true });
    };

    handleCloseSelectModal = () => {
        this.setState({ openSelectModal: false });
    };

    handleFinalStep = async () => {
        const {
            homeAddress,
            addressOne,
            addressTwo,
            city,
            stateAdd,
            country,
            zipCode,
        } = this.state;

        this.setState({
            homeAddressError: !homeAddress,
            addressOneError: !addressOne,
            addressTwoError: !addressTwo,
            cityError: !city,
            stateAddError: !stateAdd,
            countryError: !country,
            zipCodeError: !zipCode,
        });

        if (!homeAddress || !addressOne || !addressTwo || !city || !country || !zipCode) {
            this.handleErrorOpen();
        } else {
            this.handleSubmitFinalForm();
        }
    }

    handleSubmitFinalForm = async () => {
        const id = await getStorageData("tempID");
        this.setState({ btnLoader: true })
        let body = {
            data: {
                type: "email_account",
                attributes: {
                    additional_requirements: this.state.requirement,
                    anything_else_you_did_like_us_to_know: this.state.anythingElse,
                    home_address: this.state.homeAddress,
                    address_line_one: this.state.addressOne,
                    address_line_two: this.state.addressTwo,
                    city: this.state.city,
                    state: "this.state.stateAdd",
                    country: this.state.country,
                    zip_code: this.state.zipCode,
                    mobile_number: this.state.mobileNumber
                }
            }
        }

        this.updateRiderAccountApiCallId = await this.apiCallFunction({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.putMethod,
            endPoint: `${configJSON.updateRiderAccount}?id=${id}`,
            body: body,
        });
    };

    handleSuccessUpdateAccount = async () => {
        this.setState({
            btnLoader: false,
            showApiError: false,
            apiErrorMessage: "",
        });
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "SignUpRiderSuccess");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };

    handleFailureUpdateAccount = (message: string) => {
        this.setState({ showApiError: true, apiErrorMessage: message, btnLoader: false });
    };

    handleErrorOpen = () => {
        this.setState({ openErrorModal: true });
    };

    handleErrorClose = () => {
        this.setState({ openErrorModal: false });
    };

    handleGetPlace = async (event: React.ChangeEvent<HTMLInputElement>) => {
        let place = event.target.value;
        this.setState({ homeAddress: place, homeAddressError: false })
    };

    handleLogin = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }
    // Customizable Area End 
}



