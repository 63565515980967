import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment, { Moment } from "moment";
import { TimeSlot } from "./types";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  available_date: Moment;
  start_time: Moment | null;
  end_time: Moment | null;
  id: string | number;
  token: string;
  appointmentsList: TimeSlot[];
  showPicker: boolean;
    activeTab:string;
    allRides:any;
    scheduledRides:any;
    completedRides:any;
    cancelledRides:any;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppointmentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppointmentsListApiCallId?: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);

    this.state = {
      // Customizable Area Start
      id: 0,
      start_time: moment(new Date()),
      end_time: moment(endTime),
      available_date: moment(new Date()),
      appointmentsList: [],
      token: "",
      showPicker: false,
        activeTab:"1",
        allRides:[ { JobID: '#669059', PickupLocation: "Ventral Park", Destination: 'Times Square', Status: "ACCEPTED", Actions: "Update" },
          { JobID: '#669059', PickupLocation: "Ventral Park", Destination: 'Times Square', Status: "SCHEDULED", Actions:"Add Notes" },
          { JobID: '#669059', PickupLocation: "Ventral Park", Destination: 'Times Square', Status: "CANCELLED", Actions:"Add Notes" },],

        scheduledRides:[ { JobID: '#669059', PickupLocation: "Ventral Park", Destination: 'Times Square', Status: "SCHEDULED", Actions: "Update" },
          { JobID: '#669059', PickupLocation: "Ventral Park", Destination: 'Times Square', Status: "SCHEDULED", Actions:"Add Notes" },],
        completedRides:[ { JobID: '#669059', PickupLocation: "Ventral Park", Destination: 'Times Square', Status: "ACCEPTED", Actions: "Update" },
          { JobID: '#669059', PickupLocation: "Ventral Park", Destination: 'Times Square', Status: "ACCEPTED", Actions:"Add Notes" },],
        cancelledRides:[ { JobID: '#669059', PickupLocation: "Ventral Park", Destination: 'Times Square', Status: "CANCELLED", Actions: "Update" },
          { JobID: '#669059', PickupLocation: "Ventral Park", Destination: 'Times Square', Status: "CANCELLED", Actions:"Add Notes" },],

      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAppointmentsListApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          appointmentsList: responseJson.data.attributes.time_slots,
        });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);

        this.setState({ appointmentsList: [] });
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  getAppointmentList = (token: string) => {
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token,
    };

    const searchParams = {
      service_provider_id: "1",
      availability_date: this.state.available_date.format(
        configJSON.serverFormat
      ),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAppointmentsListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.appointmentAPiEndPoint}?${new URLSearchParams(
        searchParams
      ).toString()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  update(value: Partial<{ [K in keyof S]: S[K] }>) {
    this.setState((state) => ({ ...state, ...value, showPicker: false }));
  }

  getDate(value: string, format: string) {
    return moment(value).format(format);
  }

  toMomentDate(value?: string | Date | null, format?: string) {
    return moment(value, format);
  }

  toDateFromMoment(value: Moment) {
    return value.toDate();
  }

  setactiveTab=(active:string)=>{
    this.setState({activeTab:active})
   }

  navigateToAddAppointment() {
    // Merge Engine - Navigation - Start
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AddAppointment'
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigationMessage);
    // Merge Engine - Navigation - End
  }
  getCellBackgroudColor=(status:any)=>{
    if (status === 'ACCEPTED') {
      return '#D1FAE5'; 
    } else if (status === 'SCHEDULED') {
      return '#FEF3C7'; 
    } else if (status === 'CANCELLED') {
      return 'pink'; 
    }else {
      return ''; 
    }

  }
  getCellTextColor=(status:any)=>{
    if (status === 'ACCEPTED') {
      return '#059669'; 
    } else if (status === 'SCHEDULED') {
      return '#D97706'; 
    }else if (status === 'CANCELLED') {
      return 'red'; 
    } else {
      return ''; 
    }
  }
  getSampleData=(currenttab:string)=>{
      if ( currenttab=== "1") {
      return this.state.allRides; 
    } else if (currenttab === "2") {
      return this.state.scheduledRides; 
    } else if (currenttab === "3") {
      return this.state.completedRides; 
    }  else if (currenttab === "4") {
      return this.state.cancelledRides; 
    }else {
      return []; // Default case when no matching tab is found
    }
  }
  // Customizable Area End
}
