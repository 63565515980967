import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
   // Customizable Area End
}

export interface S {
  // Customizable Area Start
  AcHolderName:string;
  RNumber:string;
  AcNumber:string;
  errors:{
    AcHolderName?: string;
    RNumber?: string;
    AcNumber?: string;
  };
  formValid:boolean;
  isToastOpen:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PaymentScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchDataApiCallId: string = "";
  updateDataApiCallId:string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      AcHolderName:'',
      RNumber:'',
      AcNumber:'',
      errors: {},
      formValid:false,
      isToastOpen:false,
    };    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.fetchDataApiCallId) {
          if (responseJson.data) {

            this.updateStateFromResponse(responseJson.data);
          }
        }
        if (apiRequestCallId === this.updateDataApiCallId) {
          if (responseJson.meta.message=== "Profile updated successfully") {
            this.setState({isToastOpen:true})
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getData();
  }
  updateStateFromResponse = (data:any) => {
  if (data.driver_detail) {
    if (data.driver_detail.account_number) {
     this.setState({AcNumber:data.driver_detail.account_number})
    }

    if (data.driver_detail.routing_number) {
      this.setState({RNumber:data.driver_detail.routing_number})
    }
    if (data.driver_detail.account_holder_name) {
      this.setState({AcHolderName:data.driver_detail.account_holder_name})
    }
  }
  };
  getData = () => {
    const token: string = localStorage.getItem('token') ?? 'asd';
    const header = {
      "token": token
    };
    const data = {}
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profile/show"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    this.setState(
      (prevState) => ({
        ...prevState,
        [name]: value
      } as Pick<S, keyof S>), 
      () => {
        this.validateField(name, value);
      }
    );
  };

 

  handleSubmit = (e: any) => {
    e.preventDefault();
    if (Object.keys(this.state.errors).length === 0) {
     this.setState({formValid:true})
     this.updateData()
    } else {
      this.setState({formValid:false})
    }
  };
  updateData=()=>{
    const token: string = localStorage.getItem('token') ?? 'asd';
    const header = {
      "token": token
    };
    const formdata = new FormData();
    formdata.append("account[driver_detail_attributes][account_holder_name]",this.state.AcHolderName);
formdata.append("account[driver_detail_attributes][account_number]", this.state.AcNumber);
formdata.append("account[driver_detail_attributes][routing_number]", this.state.RNumber);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/update_driver_profile"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  validateField = (name: any, value: string) => {
    const errors:any = { ...this.state.errors };
      const requiredField = (fieldName: string, errorMsg: string) => {
      if (!value) {
        errors[fieldName] = errorMsg;
        return true;
      }
      return false;
    };
  
    // Helper function to handle length validation
    const validateLength = (fieldName: string, min: number, max: number, errorMsgMin: string, errorMsgMax: string) => {
      if (value.length < min) {
        errors[fieldName] = errorMsgMin;
      } else if (value.length > max) {
        errors[fieldName] = errorMsgMax;
      } else {
        delete errors[fieldName];
      }
    };
  
    // Validation for AcHolderName
    if (name === 'AcHolderName') {
      if (!requiredField('AcHolderName', 'Account holder name is required.')) {
        if (!/^[A-Za-z\s]+$/.test(value)) {
          errors.AcHolderName = 'Account holder name must contain only letters.';
        } else {
          validateLength('AcHolderName', 3, 30, 'Account holder name must be at least 3 characters long.', 'Account holder name cannot exceed 30 characters.');
        }
      }
    }
  
    // Validation for RNumber
    if (name === 'RNumber') {
      if (!requiredField('RNumber', 'Routing number is required.')) {
        if (!/^\d+$/.test(value)) {
          errors.RNumber = 'Routing number must contain only numbers.';
        } else {
          validateLength('RNumber', 3, 15, 'Routing number must be at least 3 digits.', 'Routing number cannot be more than 15 digits.');
        }
      }
    }
  
    // Validation for AcNumber
    if (name === 'AcNumber') {
      if (!requiredField('AcNumber', 'Account number is required.')) {
        if (!/^\d+$/.test(value)) {
          errors.AcNumber = 'Account number must contain only numbers.';
        } else {
          validateLength('AcNumber', 8, 20, 'Account number must be at least 8 digits.', 'Account number cannot exceed 20 digits.');
        }
      }
    }
  
    this.setState({ errors });
  };
  handleToastClose = () => {
    this.setState({ isToastOpen: false })
    
  }
  // Customizable Area End
}