Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "rolesandpermissions2";
exports.labelBodyText = "rolesandpermissions2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.factorAtuhtnecation = 'Two-Factor Authentication';
exports.protectAccount = 'Protect Your Account';
exports.secureAccount = 'Secure your account with simple verification';
exports.verificationCode = "We've sent a verification code to your email address. Please check your inbox and enter the code to confirm your account.";
exports.countinueButton = 'Continue';

exports.logo = 'logo';
exports.pleaseEnter = 'Please enter the 4-digit code sent to your email address';
exports.enterCode = 'Enter code here';
exports.Verify = 'Verify';
exports.didntRec = "Didn't receive the code?";
exports.resend = 'Resend email';
exports.retry = 'retry';
exports.otp = 'Resend code in';
exports.resendCode = 'Resend Code';

exports.incorrectCode = 'Incorrect code';
exports.verificationCodeIncorrect = "No worries! That code didn't match.";
exports.pleasTry = "Please enter it again carefully";
exports.tryButton = 'Try again';

exports.codeExpired = 'Code Expired';
exports.expiredWorry = "The code has expired. Don't worry, you can get a new one by tapping  'Resend Code.'";
exports.doNotReceiveCode = "Didn't receive the code?";
exports.reSendCodelink = "Resend Code";

exports.awesomeYou = "Awesome,You're all set!";
exports.youraccount = "Your account is now extra secure.";
exports.thankstaking = "Thank you for taking this important step.";
exports.genrateOtpEndpoint = "account_block/accounts/verify_otp";
exports.verifyOtpEndpoint = "account_block/accounts/verify_otp_first";
// Customizable Area End